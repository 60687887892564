

























































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter, namespace } from 'vuex-class';

import { RecordTableDto } from './../poco';

import BaseComponent from './BaseComponent.vue';

import PriceComponent from '@/components/helpers/PriceComponent.vue';
import LoadingComponent from '@/components/helpers/LoadingComponent.vue';
import ThumbsComponent from './helpers/ThumbsComponent.vue';

const ProfileGetter = namespace('profile', Getter);
const RecordAction = namespace('record', Action);

@Component({
    components: {
        LoadingComponent,
        PriceComponent,
        ThumbsComponent,
    },
})
export default class AuctionSummaryComponent extends BaseComponent {
    @ProfileGetter('getCurrentAuctions')
    private actualRecords: RecordTableDto[];
    @ProfileGetter('getEndedAuctions')
    private endedRecords: RecordTableDto[];

    @RecordAction('getDetail')
    private loadRecord: any;

    private paginationActual: any = {
      rowsPerPage: 5,
      totalItems: 0,
    };
    private paginationEnded: any = {
      rowsPerPage: 5,
      totalItems: 0,
    };
    private loading: boolean = false;
    private headers: any[] = [];

    @Watch('actualRecords')
    private changeActualRecords(actualRecords) {
        if (actualRecords !== undefined && actualRecords.length > 0) {
            this.paginationActual.totalItems = actualRecords.length;
        }
    }

    @Watch('endedRecords')
    private changeEndedRecords(endedRecords) {
        if (endedRecords !== undefined && endedRecords.length > 0) {
            this.paginationEnded.totalItems = endedRecords.length;
        }
    }

    private goToDetal(item: RecordTableDto): void {
        this.loading = true;
        this.loadRecord(item.id).then((response) => {
            const result = response as boolean;
            this.loading = false;
            if (result) {
                this.$router.push({ path: `/auctionDetail?id=${item.id}` });
            }
        });
    }

    private mounted() {
        this.headers.push({
            text: '',
            align: 'left',
            sortable: false,
            value: 'info' });
        this.headers.push({
            text: this.settings.resource.name,
            align: 'left',
            sortable: true,
            value: 'name' });
        this.headers.push({
            text: this.settings.resource.end,
            align: 'left',
            sortable: true,
            value: 'validTo' });
        this.headers.push({
            text: this.settings.resource.actualPrice,
            align: 'right',
            sortable: true,
            value: 'currentPrice' });
        this.headers.push({
            text: this.settings.resource.bids,
            align: 'right',
            sortable: true,
            value: 'numberOfBids' });
        this.headers.push({
            text: this.settings.resource.action,
            align: 'left',
            sortable: true,
            value: 'action' });
    }

    get pagesActual() {
        if (this.paginationActual.rowsPerPage == null ||
          this.paginationActual.totalItems == null) {
              return 0;
        }

        return Math.ceil(this.paginationActual.totalItems
            / this.paginationActual.rowsPerPage);
    }

        get pagesEnded() {
        if (this.paginationEnded.rowsPerPage == null ||
          this.paginationEnded.totalItems == null) {
              return 0;
        }

        return Math.ceil(this.paginationEnded.totalItems
            / this.paginationEnded.rowsPerPage);
        }
}

