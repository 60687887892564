


































import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';

import BaseComponent from '../BaseComponent.vue';

@Component({})
export default class TimePickerComponent extends BaseComponent {
    @Prop({default: null})
    private time: string;
    @Prop({default: undefined})
    private name: string;
    @Prop({default: undefined})
    private validation: object;
    @Prop({default: ''})
    private label: string;
    @Prop({default: '24hr'})
    private format: string;

    private menu: boolean = false;
    private innerTime: string = null;

    @Watch('innerTime')
    private innerTimeChange(time) {
        if (time === undefined) {
            return;
        }

        this.$emit('time', time);
    }

    private mounted() {
        this.innerTime = this.time;
    }
}

