


































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { State, Action, namespace, Getter } from 'vuex-class';

import FormBaseComponent from '../FormBaseComponent.vue';
import { ProfileState, SettingsState } from './../../store/types';
import {
    User,
    Country,
    Customer,
    Address,
} from './../../model';
import { ExtendOptions, Rule } from 'vee-validate';
import { log } from 'util';

const ProfileAction = namespace('profile', Action);
const SettingsGetter = namespace('settings', Getter);

@Component({})
export default class RegistrationComponent extends FormBaseComponent {
    @State('profile')
    private profile: ProfileState;

    @ProfileAction('setCurrentUser')
    private setUser: any;
    @ProfileAction('setCurrentUsersCustomer')
    private setCustomer: any;
    @ProfileAction('setCurrentUserCustomersAddress')
    private setAddress: any;
    @ProfileAction('newUser')
    private saveUser: any;
    @ProfileAction('checkUserName')
    private validateUser: any;
    @ProfileAction('checkEmail')
    private validateEmail: any;

    @SettingsGetter('getCountries')
    private countries: Country[];

    private newUser: User = {
        isAgreementToTerms: null,
        isActive: false,
        sendingNews: false,
        created: new Date(),
    } as User;
    private newCustomer: Customer = {
        isDealer: false,
        isFeePayed: false,
        created: new Date(),
    } as Customer;
    private newAddress: Address = {
        street: undefined,
        created: new Date(),
    } as Address;

    private state: number = 1;
    private repPassword: string = '';
    private visiblePwd: boolean = false;
    private visibleRepPwd: boolean = false;

    public mounted() {
        const userNameRule = {
            getMessage: (field) => `${field} ${this.isAlreadyUseMessage}`,
            validate: (value) => new Promise((resolve) => {
                return this.validateUser(value).then((response) => {
                    return resolve({
                        valid: response as boolean,
                    });
                });
            }),
        } as Rule;

        const emailRule = {
            getMessage: (field) => `${field} ${this.isAlreadyUseMessage}`,
            validate: (value) => new Promise((resolve) => {
                return this.validateEmail(value).then((response) => {
                    return resolve({
                        valid: response as boolean,
                    });
                });
            }),
        } as Rule;

        this.$validator.extend('userNameUnique',
            userNameRule,
            { imediate: false } as ExtendOptions);
        this.$validator.extend('emailUnique',
            emailRule,
            { imediate: false } as ExtendOptions);
    }

    private labelName(label: string): string {
        return this.settings.resource[label];
    }

    private previous(): void {
        if (this.state > 1) {
            this.state -= 1;
        }
    }

    private next(): void {
        this.$validator.validateAll().then((response) => {
            if (response) {
                switch (this.state) {
                    case 1:
                        this.newUser.customer = this.newCustomer;
                        this.setUser(this.newUser).then((nUserResponse) => {
                            if (nUserResponse) {
                                this.state += 1;
                            }
                        });
                        break;
                    case 2:
                        this.newUser.customer.address = this.newAddress;
                        this.setCustomer(this.newCustomer).then((nCustResponse) => {
                            if (nCustResponse) {
                                this.state += 1;
                            }
                        });
                        break;
                }
            }
        });
    }

    private submit(): void {
        this.$validator.validateAll().then((response) => {
            if (response) {
                if (this.state === 3) {
                    this.newAddress.country = this.countries
                        .find((f) => f.id === this.newAddress.countryId);

                    this.setAddress(this.newAddress).then((nAddrResponse) => {
                        if (nAddrResponse) {
                            this.saveUser(this.profile.user).then((saveResponse) => {
                                this.state += 1;
                            });
                        }
                    });
                }
            }
        });
    }

    get isAlreadyUseMessage(): string {
        return this.settings.resource.isAlreadyUsed.toLowerCase();
    }

    get labelUserName(): string {
        return this.settings.resource.userName;
    }

    get labelStreet(): string {
        return this.settings.resource.street;
    }

    get labelCity(): string {
        return this.settings.resource.city;
    }

    get labelPostCode(): string {
        return this.settings.resource.postCode;
    }

    get labelCountry(): string {
        return this.settings.resource.country;
    }

    get labelCompanyName(): string {
        return this.settings.resource.companyName;
    }

    get labelCompanyNumber(): string {
        return this.settings.resource.companyNumber;
    }

    get labelCompanyLegalNumber(): string {
        return this.settings.resource.companyLegalNumber;
    }

    get labelFirstName(): string {
        return this.settings.resource.firstName;
    }

    get labelLastName(): string {
        return this.settings.resource.lastName;
    }

    get labelEmail(): string {
        return this.settings.resource.email;
    }

    get labelPhoneNumber(): string {
        return this.settings.resource.phoneNumber;
    }

    get labelTitleAfter(): string {
        return this.settings.resource.titleAfter;
    }

    get labelTitleBefore(): string {
        return this.settings.resource.titleBefore;
    }

    get labelWebPageUrl(): string {
        return this.settings.resource.webPageUrl;
    }

    get labelBirthNumber(): string {
        return this.settings.resource.birthNumber;
    }

    get labelPassword(): string {
        return this.settings.resource.password;
    }

    get labelRepeatPassword(): string {
        return this.settings.resource.repeatPassword;
    }

    get labelSendingNews(): string {
        return this.settings.resource.sendingNews;
    }

    get labelIsAgreementToTerms(): string {
        return this.settings.resource.agreementToTerms;
    }

    get visible(): boolean {
        return this.visiblePwd;
    }
    set visible(value: boolean) {
        this.visiblePwd = value;
    }

    get visibleRep(): boolean {
        return this.visibleRepPwd;
    }

    set visibleRep(value: boolean) {
        this.visibleRepPwd = value;
    }
}

