






























































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { State, Getter, Action, namespace } from 'vuex-class';

import BaseComponent from '../BaseComponent.vue';
import PriceComponent from '@/components/helpers/PriceComponent.vue';
import QuestionDialogComponent from '@/components/helpers/QuestionDialogComponent.vue';
import LoadingComponent from '@/components/helpers/LoadingComponent.vue';

import {
  RecordTableDto,
  FileSimpleDto,
  UserShortDto,
  UserShortInfo,
  UserSimpleDto,
} from './../../poco';

const RecordAction = namespace('record', Action);
const RecordGetter = namespace('record', Getter);
const ProfileAction = namespace('profile', Action);

@Component({
  components: {
    PriceComponent,
    QuestionDialogComponent,
    LoadingComponent,
  },
})
export default class AdminEndedTableComponent extends BaseComponent {
  @RecordGetter('getRecords')
  private records: RecordTableDto[];

  @RecordAction('getAllEndedRecords')
  private loadRecords: any;
  @RecordAction('getDetail')
  private getDetail: any;
  @RecordAction('deleteRecord')
  private delete: any;

  @ProfileAction('getCustomerInfo')
  private getCustomerInfo: any;

  private loading: boolean = false;
  private questionDialog: boolean = false;
  private objectToDelete: RecordTableDto = undefined;
  private showCustomerInfo: boolean = false;
  private customerInfo: UserShortDto = null;

  private headers: any[] = [];
  private pagination: any = {
      rowsPerPage: 10,
      totalItems: 0,
  };

  @Watch('records') private changeUsers(records) {
      if (records !== undefined && records.length > 0) {
          this.pagination.totalItems = records.length;
      }
  }

  private mounted() {
      this.loading = true;
      this.loadRecords().then((response) => {
        this.loading = false;
      });

      this.headers.push({
          text: this.settings.resource.name,
          align: 'left',
          sortable: true,
          value: 'name' });
      this.headers.push({
          text: this.settings.resource.auctions,
          align: 'left',
          sortable: true,
          value: 'auctionName' });
      this.headers.push({
          text: this.settings.resource.validFrom,
          align: 'right',
          sortable: true,
          value: 'validFrom' });
      this.headers.push({
          text: this.settings.resource.validTo,
          align: 'right',
          sortable: true,
          value: 'validTo' });
      this.headers.push({
          text: this.settings.resource.startingPrice,
          align: 'rigth',
          sortable: true,
          value: 'startingPrice' });
      this.headers.push({
          text: this.settings.resource.minimumBid,
          align: 'right',
          sortable: true,
          value: 'minimumBid' });
      this.headers.push({
          text: this.settings.resource.currentPrice,
          align: 'right',
          sortable: true,
          value: 'currentPrice' });
      this.headers.push({
          text: this.settings.resource.numberOfBids,
          align: 'right',
          sortable: false,
          value: 'numberOfBids' });
      this.headers.push({
          text: this.settings.resource.winner,
          align: 'right',
          sortable: false,
          value: 'winner' });
      this.headers.push({
          text: this.settings.resource.action,
          align: 'rigth',
          sortable: true,
          value: 'action' });
  }

  get questionMessage(): string {
    return this.settings.resource.messageDeleteItem;
  }

  get questionWarning(): string {
    return this.settings.resource.warning;
  }

  get pages() {
      if (this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null) {
            return 0;
      }
      return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage);
  }

  private showCustomer(userId: number): void {
    this.loading = true;
    this.getCustomerInfo(userId).then((response) => {
      this.showCustomerInfo = true;
      this.customerInfo = response as UserShortDto;
      this.loading = false;
    });
  }

  private disposeCustomerInfo(): void {
    this.customerInfo = null;
    this.showCustomerInfo = false;
  }

  private wantToDeleteRecord(item: RecordTableDto): void {
    this.questionDialog = true;
    this.objectToDelete = item;
  }

  private deleteRecord(decision: boolean): void {
    if (this.objectToDelete.id > 0 && decision) {
      this.delete(this.objectToDelete.id).then((response) => {
        if (response) {
          this.questionDialog = false;
        }
      });
    } else {
      this.questionDialog = false;
      this.objectToDelete = undefined;
    }
  }
}

