









































import { Component, Prop, Watch } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';

import BaseComponent from '../BaseComponent.vue';
import CarouselItem from './CarouselItem.vue';

import { CarouselDto, CarouselItemDto } from './../../poco';

const SettingsGetter = namespace('settings', Getter);

@Component({
    components: {
        CarouselItem,
    },
})
export default class Carousel extends BaseComponent {
    @Prop({ default: undefined })
    private name: string;
    @Prop({ default: undefined })
    private data: CarouselDto;

    @SettingsGetter('getIsMobile')
    private isMobile: boolean;

    private sliderWidth: number = 0;
    private step: number = 0;
    private take: number = 1;

    @Watch('sliderWidth')
    private watchSliderWidth(sliderWidth: number) {
        if (sliderWidth !== this.sliderWidth) {
            this.sliderWidth = sliderWidth;
            this.setTake();
        }
    }

    private mounted() {
        this.sliderWidth = 0;
        this.setTake();
    }

    private moveBack(): void {
        if (this.isValidBackStep()) {
            this.step -= this.take;
        }
    }

    private moveForward(): void {
        if (this.isValidForwardStep()) {
            this.step += this.take;
        }
    }

    private showItems(): CarouselItemDto[] {
        return this.data.items.slice(this.step, this.step + this.take);
    }

    private setTake(): void {
        if (!this.isMobile) {
            this.take = 6;
        } else {
            this.take = this.data.items.length;
        }
    }

    private detail(id: number): void {
        window.location.href = `auctionDetail?id=${id}`;
    }

    private isValidForwardStep(): boolean {
        return this.step + this.take < this.data.items.length;
    }

    private isValidBackStep(): boolean {
        return this.step >= this.take;
    }

    private btnCarsText(count: number): string {
        switch (count) {
            case 1:
                return `${this.settings.resource.car.toLowerCase()}`;
            case 2:
            case 3:
                return `${this.settings.resource.fewCars.toLowerCase()}`;
            default:
                return `${this.settings.resource.cars.toLowerCase()}`;
        }
    }
}
