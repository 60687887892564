

















import BaseView from './BaseView.vue';
import Component from 'vue-class-component';
import { State} from 'vuex-class';

import { SettingsState } from './../store/types';
import { RegistrationComponent } from './../components';

@Component({
  components: {
    RegistrationComponent,
  },
})
export default class Registration extends BaseView {
    @State('settings')
    private settings: SettingsState;

    get logo(): string {
        return `${this.settings.apiUrl.replace('/api', '')}/img/home_cars_jera_trading.png`;
    }
}

