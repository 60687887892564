































































































































































































































































































































































































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { State, Getter, Action, namespace } from 'vuex-class';

import BaseComponent from '../BaseComponent.vue';
import PriceComponent from '@/components/helpers/PriceComponent.vue';
import DatePickerComponent from '@/components/helpers/DatePickerComponent.vue';
import TimePickerComponent from '@/components/helpers/TimePickerComponent.vue';
import FileUploadComponent from '@/components/helpers/FileUploadComponent.vue';
import QuestionDialogComponent from '@/components/helpers/QuestionDialogComponent.vue';
import LoadingComponent from '@/components/helpers/LoadingComponent.vue';
import BidsListComponent from '@/components/helpers/BidsListComponent.vue';

import {
  RecordTableDto,
  AuthUser,
  FileSimpleDto,
  AuctionLookupDto,
  AuctionTableDto,
  BidDto,
  UserShortDto,
} from './../../poco';

import { Record, File, Auction } from './../../model';
import { RecordState, AuthState } from './../../store/types';
import moment from 'moment-timezone';

const RecordAction = namespace('record', Action);
const RecordGetter = namespace('record', Getter);
const AuctionGetter = namespace('auction', Getter);
const AuctionAction = namespace('auction', Action);
const ProfileAction = namespace('profile', Action);

@Component({
  components: {
    PriceComponent,
    DatePickerComponent,
    FileUploadComponent,
    QuestionDialogComponent,
    LoadingComponent,
    TimePickerComponent,
    BidsListComponent,
  },
})
export default class AdminRecordTableComponent extends BaseComponent {
  @State('record') private record: RecordState;
  @State('auth') private auth: AuthState;

  @Prop({default: undefined}) private auction: AuctionTableDto;
  @Prop({default: []}) private records: RecordTableDto[];
  @Prop({default: false}) private loading: boolean;

  @RecordAction('createRecord') private create: any;
  @RecordGetter('getBids') private bids: BidDto[];
  @RecordGetter('getCurrent') private current: Record;

  @RecordAction('initialCurrent') private initCurrent: any;
  @RecordAction('getDetail') private getDetail: any;
  @RecordAction('getRecordFiles') private getRecordFiles: any;
  @RecordAction('deleteRecord') private delete: any;
  @RecordAction('updateRecord') private updateRecord: any;
  @RecordAction('setFiles') private setFiles: any;
  @RecordAction('addFiles') private addFiles: any;
  @RecordAction('setCurrentUserId') private setCurrentUserId: any;
  @RecordAction('setCurrentAuctionId') private setCurrentAuctionId: any;
  @RecordAction('setCurrentRecordDates') private setCurrentDates: any;
  @RecordAction('setCurrentRecordDatesFromAuction') private setCurrentDateFromAuction: any;
  @RecordAction('getRecordsBidForAdmin') private getRecordsBidForAdmin: any;
  @RecordAction('getAuctionRecordsForAdmin') private loadRecords: any;

  @AuctionAction('getAllLookup') private loadAuctionsCombo: any;
  @AuctionAction('getDetail') private getSelectedAuction: any;
  @AuctionAction('getAllForAdmin') private loadAuctions: any;
  @AuctionGetter('getLookupList') private auctionsCombo: AuctionLookupDto[];

  @ProfileAction('getCustomerInfo')
  private getCustomerInfo: any;

  private timeTo: string = null;
  private timeFrom: string = null;
  private questionDialog: boolean = false;
  private objectToDelete: RecordTableDto = undefined;
  private objectToMove: Record = null;
  private state: number = 1;
  private formActive: boolean = false;
  private headers: any[] = [];
  private pagination: any = {
      rowsPerPage: 10,
      totalItems: 0,
  };
  private showBidsInfo: boolean = false;
  private showMoveDialog: boolean = false;
  private bidsInfo: BidDto[] = null;

  private showCustomerInfo: boolean = false;
  private customerInfo: UserShortDto = null;

  @Watch('records') private changeUsers(records) {
      if (records !== undefined && records.length > 0) {
          this.pagination.totalItems = records.length;
      }
  }

  private showBids(itemId: number): void {
    this.getRecordsBidForAdmin(itemId).then((response) => {
       this.showBidsInfo = true;
    });
  }

  private disposeBidsInfo(): void {
    this.bidsInfo = null;
    this.showBidsInfo = false;
  }

  private disposeMoveDialog(): void {
    this.showMoveDialog = false;
  }

  private showCustomer(userId: number): void {
    this.getCustomerInfo(userId).then((response) => {
      this.showCustomerInfo = true;
      this.customerInfo = response as UserShortDto;
    });
  }

  private disposeCustomerInfo(): void {
    this.customerInfo = null;
    this.showCustomerInfo = false;
  }

  private mounted() {
      this.headers.push({
          text: this.settings.resource.name,
          align: 'left',
          sortable: true,
          value: 'name' });
      this.headers.push({
          text: this.settings.resource.auctions,
          align: 'left',
          sortable: true,
          value: 'auctionName' });
      this.headers.push({
          text: this.settings.resource.validFrom,
          align: 'right',
          sortable: true,
          value: 'validFrom' });
      this.headers.push({
          text: this.settings.resource.validTo,
          align: 'right',
          sortable: true,
          value: 'validTo' });
      this.headers.push({
          text: this.settings.resource.startingPrice,
          align: 'right',
          sortable: true,
          value: 'startingPrice' });
      this.headers.push({
          text: this.settings.resource.minimumBid,
          align: 'right',
          sortable: true,
          value: 'minimumBid' });
      this.headers.push({
          text: this.settings.resource.currentPrice,
          align: 'right',
          sortable: true,
          value: 'currentPrice' });
      this.headers.push({
          text: this.settings.resource.bids,
          align: 'right',
          sortable: false,
          value: 'numberOfBids' });
      this.headers.push({
          text: this.settings.resource.action,
          align: 'right',
          sortable: true,
          value: 'action' });
      this.loadAuctionsCombo();
  }

  get questionWarning(): string {
    return this.settings.resource.warning;
  }

  get questionMessage(): string {
    return this.settings.resource.messageDeleteItem;
  }

  get labelUploadImages(): string {
    return this.settings.resource.uploadImages;
  }

  get labelName(): string {
    return this.settings.resource.name;
  }

  get labelStartingPrice(): string {
    return this.settings.resource.startingPrice;
  }

  get labelMinimumBid(): string {
    return this.settings.resource.minimumBid;
  }

  get labelValidFrom(): string {
    return this.settings.resource.validFrom;
  }

  get labelValidTo(): string {
    return this.settings.resource.validTo;
  }

  get labelMileAge(): string {
    return this.settings.resource.mileAge;
  }

  get labelIsActive(): string {
    return this.settings.resource.active;
  }

  get labelWithVat(): string {
    return this.settings.resource.withVat;
  }

  get labelColor(): string {
    return this.settings.resource.color;
  }

  get labelFuel(): string {
    return this.settings.resource.fuel;
  }

  get labelPower(): string {
    return this.settings.resource.power;
  }

  get labelEngineCapacity(): string {
    return this.settings.resource.engineCapacity;
  }

  get labelVin(): string {
    return this.settings.resource.vehicleVinNumber;
  }

  get labelDoors(): string {
    return this.settings.resource.doors;
  }

  get labelNumberOfSeets(): string {
    return this.settings.resource.numberOfSeets;
  }

  get labelEuroNorm(): string {
    return this.settings.resource.euroNorm;
  }

  get labelAxle(): string {
    return this.settings.resource.axle;
  }

  get labelTransmission(): string {
    return this.settings.resource.transmission;
  }

  get labelDateOfFirstRegistration(): string {
    return this.settings.resource.dateOfFirstRegistration;
  }

  get labelStk(): string {
    return this.settings.resource.technicalViewOfTheVehicle;
  }

  get labelState(): string {
    return this.settings.resource.state;
  }

  get labelContactToAppointment(): string {
    return this.settings.resource.contactToAppointment;
  }

  get labelOperationWeight(): string {
    return this.settings.resource.operationWeight;
  }

  get labelMaximumWeight(): string {
    return this.settings.resource.maximumWeight;
  }

  get labelMaximumWeightOfRide(): string {
    return this.settings.resource.maximumWeightOfRide;
  }

  get labelDimensions(): string {
    return this.settings.resource.dimensions;
  }

  get labelMostTechnicallyWeightOfRide(): string {
    return this.settings.resource.mostTechnicallyWeightOfRide;
  }

  get labelMostTechnicallyAcceptableWeight(): string {
    return this.settings.resource.mostTechnicallyAcceptableWeight;
  }

  get labelRegistrationCheck(): string {
    return this.settings.resource.registrationCheck;
  }

  get labelEquipment(): string {
    return this.settings.resource.equipment;
  }

  get labelDefects(): string {
    return this.settings.resource.defects;
  }

  get labelMoreDescription(): string {
    return this.settings.resource.moreDescription;
  }

  get labelAuction(): string {
    return this.settings.resource.auctions;
  }

  get pages() {
      if (this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null) {
            return 0;
      }
      return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage);
  }

  private auctionChange(): void {
    if (this.record.current !== undefined) {

      if (this.record.current.auctionId !== undefined) {
        const auction: AuctionLookupDto = this.auctionsCombo
          .find((x) => x.id === this.record.current.auctionId);

        if (auction) {
          this.setCurrentDateFromAuction(auction);
        }
      }
    }
  }

  private saveMove(): void {
    this.updateRecord(this.record.current).then((response) => {
      if (response) {
        this.loadRecords(this.auction.id).then(() => {
          this.disposeMoveDialog();
          this.loadAuctions();
        });
      }
    });
  }

  private deleteImage(file: FileSimpleDto): void {
    if (this.record.current !== undefined) {
      const filesToRemain: FileSimpleDto[] = this.record.current.files
        .filter((x) => x.id !== file.id && x.name !== file.name);

      this.setFiles(filesToRemain.map((x) => {
          const item: File = {
            path: x.path,
            userId: x.userId,
            recordId: x.recordId,
            created: new Date(),
            user: null,
            record: null,
            name: x.name,
            id: x.id,
          } as File;

          return item;
        }));
    }
  }

  private imagePath(file: FileSimpleDto): string {
    const path: string = file.id > 0
      ? `/${file.path}/${file.recordId}/images`
      : `/tempFiles`;

    return `${path}/${file.name}`;
  }

  private backToList(): void {
    this.initCurrent().then((response) => {
      this.state = 1;
      this.formActive = false;
    });

  }

  private edit(item: RecordTableDto): void {
    if (item.id > 0) {
      // this.loading = true;
        this.getDetail(item.id).then((response) => {
            this.getRecordFiles(item.id).then((respImages) => {
                this.formActive = response as boolean;
                this.loading = false;
            });
      });
    }
  }

  private wantToDeleteRecord(item: RecordTableDto): void {
    this.questionDialog = true;
    this.objectToDelete = item;
  }

  private moveRecordDialog(item: RecordTableDto): void {
    this.showMoveDialog = true;
    this.getDetail(item.id).then((response) => {
      this.getRecordFiles(item.id).then(() => {
          this.objectToMove = response;
      });
    });
  }

  private deleteRecord(decision: boolean): void {
    if (this.objectToDelete.id > 0 && decision) {
      this.delete(this.objectToDelete.id).then((response) => {
        if (response) {
          this.questionDialog = false;
        }
      });
    } else {
      this.questionDialog = false;
      this.objectToDelete = undefined;
    }
  }

  private newRecord() {
    this.initCurrent().then((response) => {
      this.formActive = response as boolean;
      this.setCurrentUserId(this.auth.userId);
      this.setCurrentAuctionId(this.auction.id);
      this.setCurrentDateFromAuction(this.auction);
    });
  }

  private newFiles(files: File[]): void {
      this.addFiles(files.map((file) => {
          const item: File = {
            path: 'auction',
            userId: this.auth.userId,
            recordId: this.record.current.id,
            created: new Date(file.created),
            user: null,
            record: null,
            name: file.name,
            id: file.id,
          } as File;

          return item;
        }));
  }

  private back(): void {
    this.$validator.validateAll().then((response) => {
      if (response) {
        this.state -= 1;
      }
    });
  }

  private next(): void {
    this.$validator.validateAll().then((response) => {
      if (response) {
        this.state += 1;
      }
    });
  }

  private submit(): void {
    if (this.state === 4) {
      this.$validator.validateAll().then((response) => {
        if (response) {
          if (this.record.current.id === undefined ||
              this.record.current.id <= 0) {
              this.create(this.record.current).then((respRecord) => {
                if (respRecord) {
                  this.backToList();
                }
              });
          } else {
            this.updateRecord(this.record.current).then((respRecord) => {
              if (respRecord) {
                this.backToList();
              }
            });
          }
        }
      });
    }
  }
}

