























import { Component, Prop } from 'vue-property-decorator';

import BaseComponent from '../BaseComponent.vue';

@Component({})
export default class LoadingComponent extends BaseComponent {
    @Prop({default: false})
    public open: boolean;
    @Prop({default: 'loadingData'})
    public message: string;
}

