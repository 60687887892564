



































import { Component, Prop } from 'vue-property-decorator';
import { Getter, Action, namespace } from 'vuex-class';

import BaseComponent from '../BaseComponent.vue';
import LoadingComponent from './LoadingComponent.vue';
import Linggallery from 'lingallery';


import { Record } from '../../model';

import { FileSimpleDto } from '../../poco';

const RecordGetter = namespace('record', Getter);
const RecordAction = namespace('record', Action);

@Component({
    components: {
        LoadingComponent,
        Linggallery,
    },
})
export default class FileCarouselComponent extends BaseComponent {
    @Prop({ default: -1 })
    private recordId: string;

    @Prop({ default: false })
    private isWinner: boolean;

    @Prop({ default: false })
    private isBidding: boolean;

    @RecordGetter('getCurrent')
    private record: Record;

    @RecordAction('getRecordFiles')
    private files: any;

    private isLoading: boolean = false;

    private isImageSelected: boolean = false;
    private gallery = [];
    private currentId = null;

    private mounted() {
        const id: number = parseInt(this.recordId, 10);

        if (id > -1) {
            this.isLoading = true;

            this.files(id).then((result) => {
                this.isLoading = false;
                this.composeGalleryImages();
            });
        }
    }

    private filePath(file: FileSimpleDto): string {
        return `${this.settings.apiUrl.replace('/api', '')}/${file.path}/${file.recordId}/images/${file.name}`;
    }

    private openDialog(): void {
        this.isImageSelected = true;
    }

    private firstImage(): string {
        return this.gallery[0].src;
    }

    private composeGalleryImages(): void {
        this.gallery = this.record.files.map((item) => {
            return {
                src: this.filePath(item),
                thumbnail: this.filePath(item),
                id: item.id,
            };
        });
    }
}

