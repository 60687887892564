























































import BaseView from './BaseView.vue';
import Component from 'vue-class-component';
import { State } from 'vuex-class';

import { SettingsState } from './../store/types';

@Component({})
export default class Contact extends BaseView {
  @State('settings')
  private settings: SettingsState;

  get filedBy(): string {
      return this.settings.resource.filedByTheRegionalCurtInPilsen.replace('{0}', 'C 36595');
  }

  get logo(): string {
    return `${this.settings.apiUrl.replace('/api', '')}/img/home_cars_jera_trading.png`;
  }
}

