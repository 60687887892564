






















import {  Prop, Component } from 'vue-property-decorator';
import { Action, namespace } from 'vuex-class';

import Helpers from './../../helpers';
import BaseComponent from '@/components/BaseComponent.vue';

const RecordAction = namespace('record', Action);
const STATUS_INITIAL = 0;
const STATUS_SAVING = 1;
const STATUS_SUCCESS = 2;
const STATUS_FAILED = 3;

@Component({})
export default class FileUploadComponent extends BaseComponent {
    @Prop({default: 'files'})
    private name: string;
    @Prop({default: 'Upload files'})
    private title: string;

    @RecordAction('uploadFiles')
    private upload: any;

    private uploadedFiles: any[] = [];
    private currentStatus: number = null;
    private uploadError: any = null;

    private filesChange(fieldName, fileList): void {
        const formData: FormData = new FormData();

        if (!fileList.length) {
            return;
        }

        Array.from(Array(fileList.length).keys())
            .map((x) => {
                formData.append(fieldName, fileList[x], fileList[x].name);
            });

        this.save(formData);
    }

    get isInitial(): boolean {
        return this.currentStatus === STATUS_INITIAL;
    }

    get isSaving(): boolean {
        return this.currentStatus === STATUS_SAVING;
    }

    get isSuccess(): boolean {
        return this.currentStatus === STATUS_SUCCESS;
    }

    get isFailed(): boolean {
        return this.currentStatus === STATUS_FAILED;
    }

    private mounted() {
        this.reset();
    }

    private fileCountInfo(fileCount: number): string {
        if (fileCount === undefined) {
            fileCount = 0;
        }
        return this.settings.resource.uploadingFiles.replace('{0}', fileCount.toString());
    }

    private reset(): void {
        this.currentStatus = STATUS_INITIAL;
        this.uploadedFiles = [];
        this.uploadError = null;
    }

    private save(formData: FormData): void {
        this.currentStatus = STATUS_SAVING;

        this.upload(formData).then((response) => {
            this.uploadedFiles = response;
            this.currentStatus = STATUS_SUCCESS;
            this.$emit('files', this.uploadedFiles);

            this.reset();
        })
        .catch((error) => {
            this.uploadError = error.response;
            this.currentStatus = STATUS_FAILED;
        });
    }
}

