import { Dictionary } from '@/store/types';

export class Translator {
  public static languages: string[] = [
    'cs',
    // 'en',
    // 'de',
    // 'ru',
    // 'sk',
  ];
  /**
   * Set resource
   * @param key - key which is neccessary to show right text
   */
  public static setResource(key: string): Dictionary<string> {
    switch (key) {
      case 'cs':
        return this.cs;
      case 'en':
        return this.en;
      case 'de':
        return this.de;
      case 'ru':
        return this.ru;
      case 'sk':
        return this.sk;
      default:
        return this.cs;
    }
  }

  private static cs: Dictionary<string> = {
    about: 'O nás',
    address: 'Adresa',
    accordingTo: 'Podle',
    accountActivation: 'Aktivace účtu',
    accountNumber: 'Číslo účtu',
    action: 'Akce',
    active: 'Aktivní',
    actualAuction: 'Aktuálně dražíme',
    actualPrice: 'Aktuální cena',
    administration: 'Administrace',
    agree: 'Souhlasím',
    agreementToTerms: 'Souhlasím s obchodními podmínkami',
    applicationType: 'Typ žádosti',
    auctionDetailInformation: 'Informace o aukci',
    auctionHall: 'Jera Trading s.r.o.',
    auctionInErrorState: 'Aukce v chybovém stavu',
    auctionIsActive: 'Aktviní aukce',
    auctions: 'Aukce',
    auctionsAdministration: 'Administrace aukcí',
    auditControlIsProvidedBy: 'Evidenční kontrolu zajišťuje',
    autoLogoutMessage: 'Budete odhlášen(a) za: ',
    axle: 'Konfigurace nápravy',
    back: 'Zpět',
    bankConnection: 'Bankovní spojení',
    beginningOfTheAuction: 'Začátek aukce',
    bid: 'Přihodit',
    bidBadRequest: 'Příhoz se nezdařil. Zkuste to znovu.',
    bidding: 'Přihazuji',
    bidedBy: 'Přihodil',
    bidQuestionMessage: 'Opravdu chcete zvýšit na částku {0} Kč?',
    bidOverpaid: 'Váš příhoz není nejvyšší',
    bids: 'Příhozy',
    birthNumber: 'Rodné číslo',
    buildDate: 'r.v.',
    cancel: 'Zrušit',
    carInformation: 'Informace o vozidle',
    car: 'Vůz',
    cars: 'Vozů',
    city: 'Město',
    close: 'Zavřít',
    color: 'Barva',
    companyLegalNumber: 'DIČ',
    companyName: 'Název společnosti',
    companyNumber: 'IČO',
    contact: 'Kontakt',
    contactToAppointment: 'Kontaktní osoba',
    cookieConsent: `Web společnosti Jera Trading s.r.o. používá k poskytování služeb, personalizaci reklam a analýze
        návštěvnosti soubory cookie. Používáním tohoto webu s tím souhlasíte.`,
    cookies: 'Cookies',
    cookiesAll: 'Přijmout vše',
    cookiesOnlyNecessary: 'Přijmout pouze nezbytné',
    correctionPersonalInformation: 'Oprava osobních údajů',
    country: 'Země',
    created: 'Vytvořen',
    createdSuccessfully: 'Záznam úspěšně vytvořen.',
    currentPrice: 'Cena',
    customer: 'Zákazník',
    customerDetail: 'Detail zákazníka',
    date: 'Datum',
    dateOfFirstRegistration: 'Datum první registrace',
    dealer: 'Prodávající',
    delete: 'Smazat',
    defects: 'Závady',
    deleteSuccessfully: 'Záznam byl smazán',
    deletingPersonalInformation: 'Smazání osobních údajů',
    detailOfAuction: 'Detail aukce',
    didntWon: 'Nevyhrál jste',
    dimensions: 'Celkové rozměry (délka / šířka / výška)',
    doors: 'Dveře',
    edit: 'Upravit',
    equipment: 'Vybavení',
    email: 'Email',
    emailMultiple: 'Hromadný email',
    emailMultipleSent: 'Hromadný email byl odeslán',
    emailNotExists: 'Email neexistuje',
    emailSent: 'Email odeslán',
    end: 'Konec',
    ended: 'Ukončeno',
    endedAuctions: 'Ukončené aukce',
    endOfAuction: 'Konec aukce',
    engineCapacity: 'Objem motoru',
    euroNorm: 'Euronorma',
    expired: 'Vypršela',
    extend: 'Prodloužit',
    faq: 'Otázky a odpovědi',
    featureAuction: 'Budoucí aukce',
    feeExpirationDate: 'Datum vypršení poplatku',
    feeNotPayed: 'Poplatek není zaplacen',
    feeNotPayedInformation: `Nemáte zaplacenu jistinu nutnou, aby jste mohli dražit vozy a strojní techniku.
        Kontaktujte sprváce pro bližší informace.`,
    feePayed: 'Poplatek zaplacen',
    fewCars: 'Vozy',
    for: 'pro',
    filedByTheRegionalCurtInPilsen:
      'Spisová značka: {0} vedená u Krajského soudu v Plzni',
    finalPrice: 'Konečná cena',
    firstName: 'Jméno',
    forDealers: 'Pro prodejce',
    format: 'Formát',
    from: 'Od',
    fuel: 'Palivo',
    fullName: 'Celé jméno',
    gdpr: 'GDPR',
    gdprAdministration: 'Administrace GDPR',
    gdprAnnouncment: `Pro Vaši co nejpřesnější identifikaci prosím uvádějte údaje, které jste používali při naší
        společné komunikaci, ve smluvních vztazích a podobně. Pokud nám Vámi uvedené informace neumožní ověřit, že
        opravdu jednáme se subjektem údajů, jehož údaje zpracováváme, jsme oprávněni od Vás vyžadovat další doplňující
        údaje. Při nedostatku informací potřebných k ověření vaší totožnosti, nemůže být Vaše žádost řádně vyřízena.
        Prosím berte na vědomí, že za opakované nebo zjevně neodůvodněné žádosti, můžeme požadovat úhradu
        administrativních nákladů, případně máme právo odmítnout konat. Jakákoliv komunikace po obdržení Vámi zaslaného
        formuláře bude probíhat elektronicky. Odpovědi na Vaše žádosti očekávejte v zákonem stanovených lhůtách v
        e-mailových schránkách, jejichž adresy jste uvedli ve formuláři.`,
    gdprSendSuccessfully: 'Gdpr byl úspěšně odeslán.',
    gdprThanks: 'Děkujeme za odeslání formuláře. Váš tým Jera trading s.r.o.',
    grid: 'Mříž',
    home: 'Domů',
    incommingAuctions: 'Nejbližší aukce',
    intoFavorites: 'Do oblíbených',
    isAlreadyUsed: 'Je již někým použito',
    itemsUpdatedSuccessfully: 'Položky úspěšně upraveny.',
    language: 'Jazyk',
    lastName: 'Příjmení',
    link: 'Odkaz',
    loadingData: 'Nahrávám',
    login: 'Přihlásit se',
    logout: 'Odhlásit',
    maximumWeight: 'Nejvyšší povolená hmotnost',
    maximumWeightOfRide:
      'Nejvyšší technicky přípustná hmotnost jízdní soupravy',
    messageDeleteItem: 'Opravdu chcete smazat záznam?',
    mileAge: 'Najeto',
    minimumBid: 'Minimální příhoz',
    moreDescription: 'Další informace',
    mostTechnicallyWeightOfRide: 'Nejvyšší technicky přípustná hmotnost',
    mostTechnicallyAcceptableWeight:
      'Největší povolená hmotnost jízdní soupravy',
    name: 'Název',
    next: 'Další',
    new: 'Nový',
    newObjection: 'Vznesení námitky',
    newPassword: 'Nové heslo',
    news: 'Novinky',
    notActivated: 'Nezaktivovaný',
    notWinning: `Nevyhráváte`,
    numberOfBids: 'Počet příhozů',
    numberOfRecords: 'Počet položek',
    numberOfSeets: 'Počet sedadel',
    ok: 'OK',
    operationWeight: 'Provozní hmotnost',
    password: 'Heslo',
    passwordsNotSame: 'Hesla nejsou stejná.',
    passwordReset: 'Obnovení hesla',
    passwordResetFailed: `Během změny hesla vznikla neočekávaná vyjímka. Prosím
        kontaktujte náš tým podpory.`,
    payed: 'Zaplacen',
    phoneNumber: 'Telefonní číslo',
    postCode: 'PSČ',
    power: 'Výkon',
    processed: 'Zpracováno',
    protectionPersonalData: 'Ochrana osobních údajů a GDPR',
    question: 'Otázka',
    recordAdministration: 'Administrace položek',
    recordMove: 'Přesun položky',
    registration: 'Registrace',
    registrationCheck: 'Kontrolní registrace je prováděna',
    registrationCompleteInfo: `Do emailu uvedeného při registraci vám byl zaslán odkaz pro dokončení reigstrace.`,
    registrationAnnouncement: `Údaje, které jste uvedli v registraci považujeme za správné a úplně.
        Přihlašovací jméno a heslo si pečlivě uschovejte. Neposkytujte je žádné třetí osobě, a to ani na vyžádání.
        Předejdete tak možnému úniku Vašich osobních údajů. Naše společnost nenese odpovědnost za porušení výše
        uvedené povinnosti.`,
    registrationSent: 'Registrace úspěšně odeslána!',
    requiredFields: 'Povinné  pole',
    repeatPassword: 'Opakovat heslo',
    reportPersonalInformation: 'Výpis osobních údajů',
    resetTokenExpired: 'Možnost změny hesla vypršela',
    search: 'Hledej',
    seconds: 'sekund',
    selectAll: 'Vyber vše',
    seller: 'Prodejce',
    sendingNews: 'Zasílat novinky',
    share: 'Sdílet',
    show: 'Zobrazit',
    showBids: 'Zobraz příhozy',
    showDetails: 'Zobrazit detaily',
    specification: 'Technické údaje',
    state: 'Stav',
    startingPrice: 'Vyvolávací cena',
    step1: 'Krok 1/3',
    street: 'Ulice',
    subject: 'Předmět',
    submit: 'Odeslat',
    successfullyCompleted: 'Úspěšně dokončena',
    table: 'Tabulka',
    technicalViewOfTheVehicle: 'STK',
    terms: 'Podmínky',
    termsAndConditions: 'Obchodní podmínky',
    termsAndConditionsHeader: 'Všeobecné obchodní podmínky pro kupující',
    timeFrom: 'Čas od',
    timeTo: 'Čas do',
    titleAfter: 'Titul za',
    titleBefore: 'Titul před',
    to: 'Do',
    toTheEndOfAuction: 'Do konce aukce',
    transmission: 'Řazení',
    uploadFiles: ' Přetáhní  soubor(y) pro nahrání<br> nebo klikni...',
    uploadImages: 'Nahrát fotky',
    uploadingFiles: 'Nahrávám {0} souborů...',
    user: 'Uživatel',
    users: 'uživatelů',
    usersAdministration: 'Administrace uživatelů',
    userLoggedInSuccessfully: 'Úspěšně přihlášen.',
    userLoggedOutSuccessfully: 'Úspěšně odhlášen.',
    userName: 'Uživatelské jméno',
    updatedSuccessfully: 'Záznam úspěšně upraven.',
    userNotAuthenticated: 'Špatné jméno nebo heslo!',
    userNotExists: 'Uživatel neexistuje!',
    validation_max_chars: 'Maximálně {0} znaků!',
    validation_min_chars: 'Minimum znaků {0}!',
    validation_required: 'Pole je povinné',
    validation_pwd_must_be_same: 'Hesla se musejí shodovat',
    validFrom: 'Aktivní od',
    validTo: 'Aktivní do',
    vehicleVinNumber: 'VIN vozidla',
    warning: 'Pozor',
    webPageName1: 'Jera',
    webPageName2: 'Trading s.r.o.',
    webPageUrl: 'URL webových stránek',
    weColaborateWith: 'Spolupracujeme',
    winner: 'Vítěz',
    winning: 'Vyhráváte',
    withVat: 'S DPH',
    withoutVat: 'Bez DPH',
    won: 'Vyhrál jste',
    yearOfManufacture: 'Rok výroby',
  };

  private static en: Dictionary<string> = {
    about: 'About',
    address: 'Address',
    accordingTo: 'According to',
    accountActivation: 'Account activation',
    accountNumber: 'Account number',
    action: 'Action',
    active: 'Active',
    actualAuction: 'Actual auction',
    actualPrice: 'Current price',
    administration: 'Administration',
    agree: 'Agree',
    agreementToTerms: 'Agree with terms and conditions',
    applicationType: 'Application type',
    auctionDetailInformation: 'Detailed auction information',
    auctionHall: 'Jera Trading s.r.o.',
    auctionInErrorState: 'Auctions in error state',
    auctionIsActive: 'Active auctions',
    auctions: 'Auctions',
    auctionsAdministration: 'Auctions administration',
    auditControlIsProvidedBy: 'Audit control is provided by',
    autoLogoutMessage: 'You will be loged out in: ',
    axle: 'Axle',
    back: 'Back',
    bankConnection: 'Bank',
    beginningOfTheAuction: 'Beginning of the auction',
    bid: 'Bid',
    bidBadRequest: 'Your bid failed. Try it again.',
    bidding: `I'm bidding`,
    bidedBy: 'Bided by',
    bidQuestionMessage: 'Are you sure, you want to increase to {0} CZK?',
    bidOverpaid: 'You were overpaied by another user.',
    bids: 'Bids',
    birthNumber: 'Birth number',
    buildDate: 'mfg.',
    cancel: 'Cancel',
    carInformation: 'Vehicle information',
    car: 'Car',
    fewCars: 'Cars',
    for: 'for',
    cars: 'Cars',
    city: 'City',
    close: 'Close',
    color: 'Color',
    companyLegalNumber: 'Company legal number',
    companyName: 'Company name',
    companyNumber: 'Company number',
    contact: 'Contact',
    contactToAppointment: 'Contact person',
    cookieConsent:
      'Jera Trading s.r.o. website uses cookies to ensure you get the best experience on our website.',
    cookies: 'Cookies',
    cookiesAll: 'Accept all',
    cookiesOnlyNecessary: 'Use neccessary only',
    correctionPersonalInformation: 'Correction of personal information',
    country: 'Country',
    created: 'Created',
    createdSuccessfully: 'Record created successfully',
    currentPrice: 'Price',
    customer: 'Customer',
    customerDetail: `Customer's detail`,
    date: 'Date',
    dateOfFirstRegistration: 'Date of first registration',
    dealer: 'Dealer',
    delete: 'Delete',
    defects: 'Defets',
    deleteSuccessfully: 'Record deleted successfully',
    deletingPersonalInformation: 'Deleting of personal information',
    detailOfAuction: 'Detail of auction',
    dimensions: 'Overall dimensions (length / width / height)',
    didntWon: `You didn't won`,
    doors: 'Doors',
    edit: 'Edit',
    equipment: 'Equipment',
    email: 'Email',
    emailMultiple: 'Multiple email',
    emailMultipleSent: 'Multiple email has been sent.',
    emailNotExists: 'Email not exists',
    emailSent: 'Email sent',
    end: 'End',
    ended: 'Ended',
    endedAuctions: 'Ended auctions',
    endOfAuction: 'End of auction',
    engineCapacity: 'Engine capacity',
    euroNorm: 'Euronorm',
    expired: 'Expired',
    extend: 'Extend',
    faq: 'FAQ',
    featureAuction: 'Feature auctions',
    feeExpirationDate: 'Fee expiration date',
    feeNotPayedInformation: `You do not have payed auction fee. Please, contact web site operator
        for further information.`,
    feeNotPayed: 'Fee not payed',
    feePayed: 'Fee payed',
    filedByTheRegionalCurtInPilsen:
      'File number: {0} filed bz the regional curt in Pilsen',
    finalPrice: 'Final price',
    firstName: 'First name',
    forDealers: 'For dealers',
    format: 'Format',
    from: 'From',
    fuel: 'Fuel',
    fullName: 'Full name',
    gdpr: 'GDPR',
    gdprAdministration: 'GDPR administration',
    gdprAnnouncment: `
        For your most accurate identification, please provide the data you used in our shared communication,
        in contractual relations and the like. If the information provided does not allow us to verify that
        we are really dealing with the data subject whose We process the data, we are entitled to request
        additional information from you. Insufficient information to verify your identity, your request can
        not be properly processed. Please note that for repeated or manifestly unjustified requests, we may
        request payment of administrative costs, or we have the right to refuse to act. Any communication
        upon receipt by you the form will be sent electronically. You can expect the answers to your requests
        within the statutory deadlines in mailboxes whose addresses are listed on the form.`,
    gdprSendSuccessfully: 'Gdpr form sent successfully.',
    gdprThanks: 'Thank you for sending a form. Your team Jera trading s.r.o.',
    grid: 'Grid',
    home: 'Home',
    incommingAuctions: 'Incomming auctions',
    intoFavorites: 'To favorites',
    isAlreadyUsed: 'Is already used by somebody.',
    itemsUpdatedSuccessfully: 'Items successfully updated.',
    language: 'Language',
    lastName: 'Last name',
    link: 'Link',
    loadingData: 'Loading',
    login: 'Login',
    logout: 'Logout',
    maximumWeight: 'Maximum allowable weight',
    maximumWeightOfRide:
      'Technically permissible maximum mass of the combination',
    messageDeleteItem: 'Are you shure. You are going to delete record?',
    mileAge: 'Mileage',
    minimumBid: 'Minimum bid',
    moreDescription: 'More information',
    mostTechnicallyWeightOfRide: 'Technically permissible maximum weight',
    mostTechnicallyAcceptableWeight:
      'The maximum permitted weight of the combination',
    name: 'Name',
    next: 'Next',
    new: 'New',
    newObjection: 'New objection',
    newPassword: 'New password',
    news: 'News',
    notActivated: 'Not activated',
    notWinning: `You're not winning`,
    numberOfBids: 'Number of bids',
    numberOfRecords: 'Number of recors',
    numberOfSeets: 'Number of seets',
    ok: 'OK',
    operationWeight: 'Operating weight',
    password: 'Password',
    passwordsNotSame: 'Passwords are not same.',
    passwordReset: 'Password reset',
    passwordResetFailed: `Some errors occured while password reseting.
        Pleas contact our support team.`,
    phoneNumber: 'Phone number',
    postCode: 'Post code',
    power: 'Power',
    processed: 'Processed',
    protectionPersonalData: 'Protection of personal data and GDPR',
    question: 'Question',
    recordAdministration: 'Record administration',
    recordMove: 'Move record',
    registration: 'Registration',
    registrationCheck: 'Registration check is done by',
    registrationCompleteInfo: `
    A link to complete the reigstration has been sent to the email provided during registration.`,
    registrationAnnouncement: `
        The data you provided in the registration is correct and complete. Please remember to
        keep your username and password. Do not provide them to any third party, even on request.
        This will prevent your personal data from being leaked. Our company is not responsible for
        the violation above obligations.`,
    registrationSent: 'Registration sucessfully sent!',
    requiredFields: 'Required fields',
    repeatPassword: 'Repeat password',
    reportPersonalInformation: 'Report of personal information',
    resetTokenExpired: 'Token for password reset expired.',
    search: 'Search',
    seconds: 'seconds',
    selectAll: 'Select all',
    seller: 'Seller',
    sendingNews: 'Sending news',
    share: 'Share',
    show: 'Show',
    showBids: 'Show bids',
    showDetails: 'Show details',
    specification: 'Specifications',
    state: 'State',
    startingPrice: 'Starting price',
    step1: 'Step 1/3',
    street: 'Street',
    subject: 'Subject',
    submit: 'Submit',
    successfullyCompleted: 'Sucessfuly completed',
    table: 'Table',
    technicalViewOfTheVehicle: 'Technical view of the vehicle',
    terms: 'Terms',
    termsAndConditions: 'Terms and conditions',
    termsAndConditionsHeader: 'Terms and conditions',
    timeFrom: 'Time from',
    timeTo: 'Time to',
    titleAfter: 'Prefix',
    titleBefore: 'Postfix',
    to: 'To',
    toTheEndOfAuction: 'Until the end of the auction',
    transmission: 'Transmission',
    uploadFiles: ' Drag your file(s) here to begin<br> or click to browse',
    uploadImages: 'Upload images',
    uploadingFiles: 'Uploading {0} files...',
    user: 'User',
    users: 'users',
    usersAdministration: 'Users administration',
    userLoggedInSuccessfully: 'Successfully logged in.',
    userLoggedOutSuccessfully: 'Logged out successfully.',
    userName: 'User name',
    updatedSuccessfully: 'Record updated sucessfully.',
    userNotAuthenticated: 'Wrong user name or password!',
    userNotExists: 'User not exists!',
    validation_min_chars: 'At least {0} characters!',
    validation_max_chars: 'Maximum {0} characters!',
    validation_required: 'Is required!',
    validation_pwd_must_be_same: 'Passwords must be equal!',
    validFrom: 'Valid from',
    validTo: 'Valid to',
    vehicleVinNumber: 'Vehicle VIN',
    warning: 'Warning',
    webPageName1: 'Jera',
    webPageName2: 'Trading s.r.o.',
    webPageUrl: 'Web page URL',
    weColaborateWith: 'We colaborate',
    winner: 'Winner',
    winning: `You're winning`,
    withVat: 'With VAT',
    withoutVat: 'without VAT',
    won: 'You won',
    yearOfManufacture: 'Year of manufacture',
  };

  private static sk: Dictionary<string> = {};

  private static de: Dictionary<string> = {};

  private static ru: Dictionary<string> = {};
}
