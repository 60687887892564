


























































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { State, Action, Getter, namespace } from 'vuex-class';

import { Customer, EmailAddress, EmailMessage } from './../../model';
import { UserSimpleDto } from './../../poco';
import { ProfileState } from './../../store/types';
import BaseComponent from '../BaseComponent.vue';
import { filter } from 'vue/types/umd';
import { textChangeRangeIsUnchanged } from 'typescript';

const ProfileAction = namespace('profile', Action);
const ProfileGetter = namespace('profile', Getter);

@Component({})
export default class UserTableComponent extends BaseComponent {
    @State('profile')
    private profile: ProfileState;

    @Prop({default: []})
    private users: UserSimpleDto[];
    @Prop({default: true})
    private loading: boolean;

    @ProfileAction('setCurrentUser')
    private setUser: any;
    @ProfileAction('updateUserAdmin')
    private updateUser: any;
    @ProfileAction('sendMultipleEmail')
    private sendMultipleEmail: any;
    @ProfileAction('filterUsers')
    private filterUsers: any;

    @ProfileGetter('getCurrentCustomer')
    private currentCustomer: Customer;

    private actionInProgress: boolean = false;
    private sendingEmails: boolean = false;
    private dialog: boolean = false;
    private emailDialog: boolean = false;
    private headers: any[] = [];
    private pagination: any = {
        rowsPerPage: 10,
        totalItems: 0,
    };
    private emails: string[] = [];

    private searchType: string = '';
    private searchCompanyNumber: string = '';
    private searchTypeItems: any = [];
    private isActive: boolean = false;
    private isFeePayed: boolean = false;
    private selectAll: boolean = false;
    private searchText: string = '';
    private filterSorceUserList: UserSimpleDto[] = [];
    private emailMessage: EmailMessage = null;

    @Watch('users')
    private changeUsers(users) {
        if (users !== undefined && users.length > 0) {
            this.pagination.totalItems = users.length;
        }

        if (this.filterSorceUserList.length <= 0) {
            Object.assign(this.filterSorceUserList, this.users);
        }
    }

    @Watch('searchText')
    private searchTextFnc(text) {
        let filteredUsers: UserSimpleDto[] = [];
        Object.assign(filteredUsers, this.filterSorceUserList);

        if (text.length > 0) {
            filteredUsers = this.filterByType(filteredUsers, this.searchType, text);
        }

        if (this.isActive) {
            filteredUsers = this.filterIsActive(filteredUsers);
        }

        if (this.isFeePayed) {
            filteredUsers = this.filterIsFeePayed(filteredUsers);
        }

        this.filterUsers(filteredUsers);
    }

    @Watch('selectAll')
    private selectAllFnc(selectAll) {
        this.filterSorceUserList.forEach((item) => {
            item.sendEmail = selectAll;
        });
    }

    @Watch('isActive')
    private changeFilterIsActive(isActive) {
        let filteredUsers: UserSimpleDto[] = [];
        Object.assign(filteredUsers, this.filterSorceUserList);

        if (this.searchText.length > 0) {
            filteredUsers = this.filterByType(filteredUsers, this.searchType, this.searchText);
        }

        if (isActive) {
            filteredUsers = this.filterIsActive(filteredUsers);
        }

        if (this.isFeePayed) {
            filteredUsers = this.filterIsFeePayed(filteredUsers);
        }

        this.filterUsers(filteredUsers);
    }

    @Watch('isFeePayed')
    private changeFilterIsEnded(isFeePayed) {
        let filteredUsers: UserSimpleDto[] = [];
        Object.assign(filteredUsers, this.filterSorceUserList);

        if (this.searchText.length > 0) {
            filteredUsers = this.filterByType(filteredUsers, this.searchType, this.searchText);
        }

        if (this.isActive) {
            filteredUsers = this.filterIsActive(filteredUsers);
        }

        if (isFeePayed) {
            filteredUsers = this.filterIsFeePayed(filteredUsers);
        }

        this.filterUsers(filteredUsers);
    }

    private edit(item: UserSimpleDto): void {
        this.setUser(item).then((response) => {
            this.dialog = response;
        });
    }

    private showEmailDialog(): void {
        this.emailDialog = true;
    }

    private closeDialog(): void {
        this.dialog = false;
    }

    private closeEmailDialog(): void {
        this.emailDialog = false;
    }

    private saveEdit(): void {
        this.actionInProgress = true;
        this.updateUser(this.profile.user).then((response) => {
            this.actionInProgress = false;
            this.closeDialog();
        });
    }

    private sendEmails(): void {
        this.sendingEmails = true;

        const addresses = this.filterSorceUserList
            .filter((item) => item.sendEmail)
            .map((item) => {

                const address: EmailAddress = {
                    name: item.userName,
                    address: item.email,
                };

                return address;
            });

        this.emailMessage.toAddresses = addresses;

        this.sendMultipleEmail(this.emailMessage).then((response) => {
            this.sendingEmails = false;

            this.setEmailObject();
            this.closeEmailDialog();
        });
    }

    private filterByType(source: UserSimpleDto[], type: string, text: string): UserSimpleDto[] {
        switch (type) {
            case 'userName':
                return source.filter((item) =>
                    item.userName.toLowerCase().includes(text.toLowerCase()));
                break;
            case 'email':
                return source.filter((item) =>
                    item.email.toLowerCase().includes(text.toLowerCase()));
                break;
            case 'phoneNumber':
                return source.filter((item) =>
                    item.phoneNumber.toLowerCase().includes(text.toLowerCase()));
                break;
            case 'birthNumber':
                return source.filter((item) => item.birthNumber != null
                    && text != null
                    && item.birthNumber.toLowerCase().includes(text.toLowerCase()));
                break;
            case 'companyNumber':
                return source.filter((item) => item.companyNumber != null
                    && text != null
                    && item.companyNumber.toLowerCase().includes(text.toLowerCase()));
                break;
            default:
                return source;
                break;
        }
    }

    private filterIsActive(source: UserSimpleDto[]): UserSimpleDto[] {
        return source.filter((item) => item.isActive);
    }

    private filterIsFeePayed(source: UserSimpleDto[]): UserSimpleDto[] {
        return source.filter((item) => item.isFeePayed);
    }

    private mounted() {
        this.headers.push({
            text: '',
            align: 'left',
            sortable: true,
            value: 'email' });
        this.headers.push({
            text: this.settings.resource.userName,
            align: 'left',
            sortable: true,
            value: 'userName' });
        this.headers.push({
            text: this.settings.resource.email,
            align: 'left',
            sortable: true,
            value: 'email' });
        this.headers.push({
            text: this.settings.resource.phoneNumber,
            align: 'left',
            sortable: true,
            value: 'phoneNUmber' });
        this.headers.push({
            text: this.settings.resource.companyNumber,
            align: 'left',
            sortable: true,
            value: 'companyNumber' });
        this.headers.push({
            text: this.settings.resource.birthNumber,
            align: 'left',
            sortable: true,
            value: 'birthNumber' });
        this.headers.push({
            text: this.settings.resource.active,
            align: 'center',
            sortable: true,
            value: 'isActive' });
        this.headers.push({
            text: this.settings.resource.news,
            align: 'center',
            sortable: false,
            value: 'sendingNews' });
        this.headers.push({
            text: this.settings.resource.created,
            align: 'left',
            sortable: true,
            value: 'created' });
        this.headers.push({
            text: this.settings.resource.feePayed,
            align: 'center',
            sortable: true,
            value: 'isFeePayed' });
        this.headers.push({
            text: this.settings.resource.action,
            align: 'center',
            sortable: true,
            value: 'action' });

        this.searchTypeItems.push(
            {id: 'userName', name: this.settings.resource.userName},
            {id: 'email', name: this.settings.resource.email},
            {id: 'phoneNumber', name: this.settings.resource.phoneNumber},
            {id: 'birthNumber', name: this.settings.resource.birthNumber},
            {id: 'companyNumber', name: this.settings.resource.companyNumber},
        );

        this.searchType = this.searchTypeItems[0].id;

        this.setEmailObject();
    }

    private setEmailObject(): void {
        this.emailMessage = {
            fromAddresses: [],
            toAddresses: [],
            subject: '',
            content: '',
        };
    }

    private activeColor(item: UserSimpleDto): boolean {
        return item.isActive && !item.isFeePayed;
    }

    private notActiveColor(item: UserSimpleDto): boolean {
        return !item.isActive;
    }

    private feePayedColor(item: UserSimpleDto): boolean {
        return item.isFeePayed && item.isActive;
    }

    private canSendEmails(): boolean {
        return this.filterSorceUserList.filter((item) => item.sendEmail).length > 0
            && this.emailMessage.content.length > 0
            && this.emailMessage.subject.length > 0;
    }

    private numberOfSelectedUsers(): number {
        return this.filterSorceUserList.filter((user) => user.sendEmail).length;
    }

    get labelIsActive(): string {
        return this.settings.resource.active;
    }

    get labelIsFeePayed(): string {
        return this.settings.resource.feePayed;
    }

    get labelSelectAll(): string {
        return this.settings.resource.selectAll;
    }

    get labelSearch(): string {
        return this.settings.resource.search;
    }

    get labelSubject(): string {
        return this.settings.resource.subject;
    }

    get labelSearchTypes(): string {
        return this.settings.resource.accordingTo;
    }

    get labelCompanyNumber(): string {
        return this.settings.resource.companyNumber;
    }

    get labelEmail(): string {
        return this.settings.resource.email;
    }

    get pages() {
        if (this.pagination.rowsPerPage == null ||
            this.pagination.totalItems == null) {
                return 0;
        }
        return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage);
    }


}

