export const AUTH_INITIAL_STATE = 'AUTH_INITIAL_STATE';
export const AUTH_LOGIN_USER = 'AUTH_LOGIN_USER';
export const AUTH_LOGOUT_USER = 'AUTH_LOGOUT_USER';
export const AUTH_SET_LANGUAGE = 'AUTH_SET_LANGUAGE';
export const AUTH_TAKE_SECOND_TO_LOGOUT = 'AUTH_TAKE_SECOND_TO_LOGOUT';
export const AUTH_SET_TIMER = 'AUTH_SET_TIMER';
export const AUTH_STOP_TIMER = 'AUTH_STOP_TIMER';
export const AUTH_RESET_TIMER = 'AUTH_RESET_TIMER';

export const RECORD_INITIAL_STATE = 'RECORD_INITIAL_STATE';
export const RECORD_CHANGE_LIST_STATE = 'RECORD_CHANGE_LIST_STATE';
export const RECORD_CHANGE_CURRENT_STATE = 'RECORD_CHANGE_CURRENT_STATE';
export const RECORD_INITIAL_CURRENT = 'RECORD_INITIAL_CURRENT';
export const RECORD_APPEND_CURRENT_FILES = 'RECORD_APPEND_CURRENT_FILES';
export const RECORD_DELETE_RECORD_FROM_LIST = 'RECORD_DELETE_RECORD_FROM_LIST';
export const RECORD_SET_ENDED_RECORDS = 'RECORD_SET_ENDED_RECORDS';
export const RECORD_CLEAR_ENDED_RECORDS = 'RECORD_CLEAR_ENDED_RECORDS';
export const RECORD_SET_CURRENT_FILES = 'RECORD_SET_CURRENT_FILES';
export const RECORD_SET_VALID_DATES = 'RECORD_SET_VALID_DATES';
export const RECORD_SET_VALID_TIMES = 'RECORD_SET_VALID_TIMES';
export const RECORD_SET_CURRENT_USER_ID = 'RECORD_SET_CURRENT_USER_ID';
export const RECORD_SET_CURRENT_AUCTION_ID = 'RECORD_SET_CURRENT_AUCTION_ID';
export const RECORD_CHANGE_BIDS_TO_CURRENT = 'RECORD_CHANGE_BIDS_TO_CURRENT';
export const RECORD_CHANGE_NUMBER_OF_BIDS_TO_CURRENT = 'RECORD_CHANGE_NUMBER_OF_BIDS_TO_CURRENT';
export const RECORD_CHANGE_CURRENT_PRICE_TO_START_PRICE = 'RECORD_CHANGE_CURRENT_PRICE_TO_START_PRICE';
export const RECORD_CHANGE_WINNING_USER_ID = 'RECORD_CHANGE_WINNING_USER_ID';
export const RECORD_UPDATE_LIST_STATE = 'RECORD_UPDATE_LIST_STATE';
export const RECORD_SET_CURRENT_PRICE = 'RECORD_SET_CURRENT_PRICE';
export const RECORD_EXTEND_VALID_TO = 'RECORD_EXTEND_VALID_TO';
export const RECORD_TAKE_ON_BID_FROM_CURRENT = 'RECORD_TAKE_ON_BID_FROM_CURRENT';
export const RECORD_NUMBER_OF_BIDS_DECREASE = 'RECORD_NUMBER_OF_BIDS_DECREASE';

export const SETTINGS_INITIAL_STATE = 'SETTINGS_INITIAL_STATE';
export const SETTINGS_CHANGE_LANG = 'SETTINGS_CHANGE_LANG';
export const SETTINGS_LOAD_COUNTRIES = 'SETTINGS_LOAD_COUNTRIES';
export const SETTINGS_CHANGE_DATA_VIEW = 'SETTINGS_CHAGE_DATA_VIEW';
export const SETTINGS_CHANGE_FORM_VIEW = 'SETTINGS_CHANGE_FORM_VIEW';
export const SETTINGS_CHANGE_DEVICE = 'SETTINGS_CHANGE_DEVICE';

export const USER_INITIAL_STATE = 'USER_INITIAL_STATE';
export const USER_INSERT = 'USER_INSERT';
export const USER_DELETE = 'USER_DELETE';
export const USER_CHANGE = 'USER_CHANGE';
export const USER_SET_CURRENT_USER = 'USER_SET_CURRENT_USER';
export const USER_SET_CURRENT_USERS_CUSTOMER =
  'USER_SET_CURRENT_USERS_CUSTOMER';
export const USER_SET_CURRENT_USER_CUSTOMERS_ADDRESS =
  'USER_SET_CURRENT_USER_CUSTOMERS_ADDRESS';
export const USER_CHANGE_ADMIN_LIST = 'USER_CHANGE_ADMIN_LIST';
export const USER_CHANGE_CURRENT_VALIDATE_USER =
  'USER_CHANGE_CURRENT_VALIDATE_USER';
export const USER_CHANGE_CURRENT_VALIDATE_EMAIL =
  'USER_CHANGE_CURRENT_VALIDATE_EMAIL';
export const USER_CHANGE_USERS_CURRENT_AUCTIONS =
  'USER_CHANGE_USERS_CURRENT_AUCTIONS';
export const USER_CHANGE_USERS_ENDED_AUCTIONS =
  'USER_CHANGE_USERS_ENDED_AUCTIONS';
export const USER_CHANGE_CURRENT_CUSTOMER = 'USER_CHANGE_CURRENT_CUSTOMER';

export const MESSAGE_INITIAL_STATE = 'MESSAGE_INITIAL_STATE';
export const MESSAGE_CHANGE_STATE = 'MESSAGE_CHANGE_STATE';
export const MESSAGE_SET_TIMEOUT = 'MESSAGE_SET_TIMEOUT';

export const AUCTION_INITIAL_STATE = 'AUCTION_INITIAL_STATE';
export const AUCTION_INITIAL_CURRENT = 'AUCTION_INITIAL_CURRENT';
export const AUCTION_CHANGE_TABLE_STATE = 'AUCTION_CHANGE_TABLE_STATE';
export const AUCTION_CHANGE_LOOKUP_STATE = 'AUCTION_CHANGE_LOOKUP_STATE';
export const AUCTION_CHANGE_CURRENT_STATE = 'AUCTION_CHANGE_CURRENT_STATE';
export const AUCTION_CHANGE_AUCTIONS_STATE = 'AUCTION_CHANGE_AUCTIONS_STATE';
export const AUCTION_DELETE_RECORD_FROM_LIST =
  'AUCTION_DELETE_RECORD_FROM_LIST';
