




































import { Component, Prop } from 'vue-property-decorator';

import BaseComponent from '../BaseComponent.vue';
import PriceComponent from './PriceComponent.vue';

import { CarouselItemDto } from './../../poco';

@Component({
  components: {
    PriceComponent,
  },
})
export default class CarouselItem extends BaseComponent {
  @Prop({ default: undefined })
  private item: CarouselItemDto;

  private detail(id: number): void {
      this.$emit('detailCarousel', id);
  }
}
