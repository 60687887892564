


















import Component from 'vue-class-component';

import BaseView from '../BaseView.vue';
import AdminEndedTableComponent from '@/components/auction/AdminEndedTableComponent.vue';

@Component({
  components: {
    AdminEndedTableComponent,
  },
})
export default class AuctionEndedAdministration extends BaseView {
}

