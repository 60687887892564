
































import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter, namespace } from 'vuex-class';

import BaseComponent from '../BaseComponent.vue';
import QuestionDialogComponent from './QuestionDialogComponent.vue';

import { Record, Bid } from './../../model';
import { AuthUser } from './../../poco';

const RecordAction = namespace('record', Action);
const RecordGetter = namespace('record', Getter);
const AuthGetter = namespace('auth', Getter);

@Component({
    components: {
        QuestionDialogComponent,
    },
})
export default class BidComponent extends BaseComponent {
    @Prop({ default: 0 })
    private bid: number;

    @RecordAction('addBid')
    private addBid: any;

    @RecordGetter('getCurrent')
    private record: Record;

    @AuthGetter('getCurrentLoggedUser')
    private user: AuthUser;

    private loading: boolean = false;
    private localBid: number = 0;
    private disable: boolean = false;
    private questionDialog: boolean = false;

    private mounted() {
        this.localBid = this.bid;
    }

    @Watch('localBid')
    private watchBid(newBid: string) {
        if (!/^[0-9]+$/.test(newBid)) {
            this.disable = true;
        } else {
            if (this.record.bids.length === 0 && this.record.currentPrice === parseInt(newBid, 10)) {
                this.disable = false;
            } else if (
                parseInt(newBid, 0) <
                this.record.minimumBid + this.record.currentPrice
            ) {
                this.disable = true;
            } else {
                this.disable = false;
            }
            this.localBid = parseInt(newBid, 0);
        }
    }

    @Watch('record.currentPrice')
    private watchPrice(newPrice: number) {
        if (newPrice > this.localBid) {
            this.localBid = newPrice + this.record.minimumBid;
        }
    }

    private minValueValidation(currentPrice: number) {
        if (this.record.bids.length === 0) {
            return this.record.currentPrice;
        } else {
            return this.record.currentPrice + this.record.minimumBid;
        }
    }

    private submitBid(decision: boolean): void {
        // bid was canceled by user
        if (!decision) {
            this.questionDialog = false;
            return;
        }

        this.loading = true;
        this.disable = true;
        const bidPrice = this.localBid;
        this.addBid({
            recordId: this.record.id,
            price: bidPrice,
            created: new Date(),
            userId: this.user.userId,
        } as Bid)
            .then((response) => {
                this.loading = false;
                this.disable = false;
                this.questionDialog = false;

                this.localBid = (response as number) + this.record.minimumBid;
            });
    }

    get questionMessage(): string {
        return this.settings.resource.bidQuestionMessage
            .replace('{0}', this.localBid.toString());
    }

    get headerMessage(): string {
        return this.settings.resource.warning;
    }
}
