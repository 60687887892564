








































































import { State } from 'vuex-class';
import Component from 'vue-class-component';

import BaseView from '../BaseView.vue';
import { SettingsState } from './../../store/types';

@Component({})
export default class Results extends BaseView {
  @State('settings')
  private settings: SettingsState;

  private validationExpired: boolean = false;
  private validationSuccessfully: boolean = false;
  private passwordResetFailed: boolean = false;
  private successfullyRegistered: boolean = false;
  private passwordResetSuccessfully: boolean = false;

  public mounted() {
      switch (this.$route.name) {
        case 'validationExpired':
            this.validationExpired = true;
            break;
        case 'validationSucessfully':
            this.validationSuccessfully = true;
            break;
        case 'passwordResetFailed':
            this.passwordResetFailed = true;
            break;
        case 'passwordResetSuccessfully':
            this.passwordResetSuccessfully = true;
            break;
        case 'successfullyRegistered':
            this.successfullyRegistered = true;
            break;
      }
  }

  get logo(): string {
    return `${this.settings.apiUrl.replace('/api', '')}/img/home_cars_jera_trading.png`;
  }

  get exspired(): string {
      return this.settings.resource.expired.toLowerCase();
  }

  get successfullyCompleted(): string {
      return this.settings.resource.successfullyCompleted.toLowerCase();
  }
}

