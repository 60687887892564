













import { Component } from 'vue-property-decorator';

import BaseView from '@/views/BaseView.vue';
import { AuctionGridComponent } from './../components';

@Component({
  components: {
    AuctionGridComponent,
  },
})
export default class Auction extends BaseView {
}

