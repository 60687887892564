


























































import { Component, Prop } from 'vue-property-decorator';
import { Action, Getter, namespace } from 'vuex-class';

import { Address, Country } from './../model';
import BaseComponent from './BaseComponent.vue';

const SettingsGetter = namespace('settings', Getter);
const ProfileAction = namespace('profile', Action);

@Component({})
export default class CustomerDetailComponent extends BaseComponent {
  @Prop({default: undefined})
  private address: Address;

  @SettingsGetter('getCountries')
  private countries: Country[];

  @ProfileAction('updateAddress')
  private update: any;

  private isLoading: boolean = false;

  private submitAddress(): void {
    this.$validator.validateAll().then((response) => {
      if (response) {
        this.isLoading = true;
        this.update(this.address).then((responseAddres) => {
          if (responseAddres) {
            this.isLoading = false;
          }
        });
      }
    });
  }

  get labelStreet(): string {
    return super.settings.resource.street;
  }
  get labelCity(): string {
    return super.settings.resource.city;
  }
  get labelPostCode(): string {
    return super.settings.resource.postCode;
  }
  get labelCountry(): string {
    return super.settings.resource.country;
  }
}

