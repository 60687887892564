
































import { State, Action, namespace } from 'vuex-class';
import Component from 'vue-class-component';

import BaseView from './BaseView.vue';
import {
  RegistrationComponent,
  HomeComponent,
  ActualRandomComponent,
} from './../components';

import { SettingsState, AuthState } from './../store/types';

const ProfileAction = namespace('profile', Action);

@Component({
  components: {
    RegistrationComponent,
    HomeComponent,
    ActualRandomComponent,
  },
})
export default class Home extends BaseView {
  @State('settings')
  private settings: SettingsState;
  @State('auth')
  private auth: AuthState;

  @ProfileAction('initialState')
  private init: any;

  public mounted() {
    this.init();
  }

  get logo(): string {
    return `${this.settings.apiUrl.replace('/api', '')}/img/home_cars_jera_trading.png`;
  }
}

