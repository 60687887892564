
























































































import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter, namespace } from 'vuex-class';

import FormBaseComponent from '@/components/FormBaseComponent.vue';
import BidsListComponent from '@/components/helpers/BidsListComponent.vue';

import { PriceComponent, CountdownComponent, BidComponent } from './../../components';
import { Record } from './../../model';
import { AuthUser } from './../../poco';

const RecordAction = namespace('record', Action);
const RecordGetter = namespace('record', Getter);
const AuthGetter = namespace('auth', Getter);

@Component({
    components: {
        PriceComponent,
        CountdownComponent,
        BidComponent,
        BidsListComponent,
    },
})
export default class ActualRandomComponent extends FormBaseComponent {
    @RecordAction('getActualRandom')
    private randomRecord: any;

    @RecordGetter('getCurrent')
    private record: Record;

    @AuthGetter('getCurrentLoggedUser')
    private currentUser: AuthUser;

    private canBid: boolean = false;
    private checkEndAucitonCoutner: any = null;

    public mounted() {
        this.randomRecord().then((response) => {
            clearInterval(this.checkEndAucitonCoutner);
            this.startCheckEndAuction();
        });
    }

    private beforeDestroy() {
        if (this.checkEndAucitonCoutner !== null) {
            clearInterval(this.checkEndAucitonCoutner);
        }
    }

    private firstImagePath(record: Record): string {
        const { files } = record;
        if (files.length === 0) {
            return '';
        }
        const rf = files[0];
        return `/${rf.path}/${rf.recordId}/images/${rf.name}`;
    }

    private startCheckEndAuction(): void {
        this.checkEndAucitonCoutner = setInterval(() => {
            if (this.record !== null &&
                !this.canBidFnc(this.record.validFrom, this.record.validTo)) {
                clearInterval(this.checkEndAucitonCoutner);
            }
        }, 1000);
    }

    private canBidFnc(validFrom: Date, validTo: Date): boolean {
        const canBid =  new Date(validFrom) <= new Date()
            && new Date(validTo) >= new Date();

        this.canBid = canBid;

        return canBid;
    }

    private recordIdToString(record: Record): string {
      return record.id.toString();
    }

    private validTo(validTo: Date): string {
        return validTo.toString();
    }

    private detail(record: Record): void {
        if (record) {
            this.$router.push({ path: `/auctionDetail?id=${record.id}` });
        }
    }

    get minimumBid(): number {
        return this.record.currentPrice + this.record.minimumBid;
    }
}

