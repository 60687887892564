


































































































































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { Getter, Action, namespace } from 'vuex-class';
import { clearTimeout, setTimeout } from 'timers';

import BaseComponent from './BaseComponent.vue';

import CountdownComponent from './helpers/CountdownComponent.vue';
import PriceComponent from './helpers/PriceComponent.vue';
import LoadingComponent from './helpers/LoadingComponent.vue';
import Carousel from './helpers/Carousel.vue';
import BidComponent from './helpers/BidComponent.vue';
import FileCarouselComponent from './helpers/FileCarouselComponent.vue';

import {
    Record,
    Bid,
    User,
} from './../model';

import {
    FileSimpleDto,
    AuthUser,
    RecordTableDto,
    CarouselDto,
} from './../poco';

const AuthGetter = namespace('auth', Getter);
const RecordGetter = namespace('record', Getter);
const RecordAction = namespace('record', Action);
const AuctionGetter = namespace('auction', Getter);
const AuctionAction = namespace('auction', Action);

@Component({
    components: {
        PriceComponent,
        BidComponent,
        CountdownComponent,
        Carousel,
        LoadingComponent,
        FileCarouselComponent,
    },
})
export default class AuctionDetalComponent extends BaseComponent {
    @AuthGetter('getCurrentLoggedUser')
    private currentUser: AuthUser;

    @RecordGetter('getCurrent')
    private record: Record;

    @RecordGetter('getCurrentWinnerId')
    private winnerId: number;

    @RecordAction('getDetail')
    private detail: any;
    @RecordAction('loadAllPublished')
    private loadRecods: any;
    @RecordAction('getRecordsLastBid')
    private updateWinnerId: any;
    @RecordAction('getBids')
    private bids: any;

    @AuctionGetter('getAuctionsCarousel')
    private auctions: CarouselDto[];

    @AuctionAction('getFutureAutions')
    private featuredAcutions: any;

    private isLoading: boolean = false;
    private expander1: boolean[] = [true, true];
    private expander2: boolean[] = [true, true, true, true];
    private canBid: boolean = false;
    private isMounted: boolean = false;

    private winnigRefreshCounter: any = null;
    private checkEndAucitonCoutner: any = null;

    @Watch('record.bids')
    private watchBids(newBids) {
        if (this.winnigRefreshCounter == null) {
            clearInterval(this.winnigRefreshCounter);
            this.startWinningRefreshCounter();
        }
    }

    private mounted() {
        if (this.recordId() !== undefined) {

            this.isLoading = true;

            this.updateWinnerId(this.recordId());

            this.detail(this.recordId()).then((result) => {
                this.isMounted = true;

                this.bids(this.recordId());

                clearInterval(this.checkEndAucitonCoutner);
                this.startCheckEndAuction();
                this.isLoading = false;
            });
        } else {
            clearInterval(this.checkEndAucitonCoutner);
            this.startCheckEndAuction();
        }

        this.featuredAcutions();

        clearInterval(this.winnigRefreshCounter);
        this.startWinningRefreshCounter();
    }

    private startWinningRefreshCounter(): void {
        this.winnigRefreshCounter = setInterval(() => {
            this.updateWinnerId(this.recordId());
        }, 30000); // every 30s
    }

    private startCheckEndAuction(): void {
        this.checkEndAucitonCoutner = setInterval(() => {
            if (!this.canBidFnc(this.record.validFrom, this.record.validTo)) {
                clearInterval(this.checkEndAucitonCoutner);
            }
        }, 1000);
    }

    private beforeDestroy() {
        if (this.winnigRefreshCounter !== null) {
            clearInterval(this.winnigRefreshCounter);
        }

        if (this.checkEndAucitonCoutner !== null) {
            clearInterval(this.checkEndAucitonCoutner);
        }
    }

    private updated() {
        window.scrollTo(0, 0);
    }

    private recordId(): string | string[] {
        return this.$route.query.id;
    }

    private filePath(file: FileSimpleDto): string {
        return `${this.settings.apiUrl.replace('/api', '')}/${file.path}/${file.recordId}/images/${file.name}`;
    }

    private dateToString(date: Date): string {
        if (date !== undefined) {
            return date.toString();
        }
    }

    private isCurrentUserBidding(): boolean {
        const biddingIds = this.record.bids.map((x) => x.userId);

        if (biddingIds.length <= 0) {
            return false;
        }

        const bidding = biddingIds.indexOf(this.currentUser.userId) !== -1;

        return this.currentUser.isAuthenticated && this.currentUser.isFeePayed && bidding;
    }

    private canBidFnc(validFrom: Date, validTo: Date): boolean {
        const canBid = new Date(validFrom) <= new Date()
            && new Date(validTo) >= new Date();

        this.canBid = canBid;

        return canBid;
    }

    private loadDetail(id: number): void {
        this.isLoading = true;
        this.detail(id).then((response) => {
            const result = response as boolean;
            this.isLoading = false;
            if (result) {
                this.$router.push({ path: `/auctionDetail?id=${id}` });
            }
        });
    }

    get minimumBid(): number {
        const bid = this.record.bids.length === 0
            ? this.record.currentPrice
            : this.record.currentPrice + this.record.minimumBid;
        return bid;
    }

    get recordIdString(): string {
        if (this.record.id !== undefined) {
            return `record-${this.record.id}`;
        }
    }
}

