






























































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { State, Action, Getter, namespace } from 'vuex-class';

import BaseComponent from '../BaseComponent.vue';
import DatePickerComponent from '@/components/helpers/DatePickerComponent.vue';
import QuestionDialogComponent from '@/components/helpers/QuestionDialogComponent.vue';
import TimePickerComponent from '@/components/helpers/TimePickerComponent.vue';
import AdminRecordTableComponent from '@/components/auction/AdminRecordTableComponent.vue';

import { AuctionTableDto, RecordTableDto } from './../../poco';
import { Auction, Record } from './../../model';
import { AuctionState } from './../../store/types';

const AuctionAction = namespace('auction', Action);
const RecordAction = namespace('record', Action);
const RecordGetter = namespace('record', Getter);

@Component({
  components: {
    DatePickerComponent,
    QuestionDialogComponent,
    TimePickerComponent,
    AdminRecordTableComponent,
  },
})
export default class AdminAuctionTableComponent extends BaseComponent {
  @State('auction')
  private auction: AuctionState;

  @Prop({default: []})
  private auctions: AuctionTableDto[];
  @Prop({default: true})
  private loading: boolean;

  @AuctionAction('initialCurrent')
  private initCurrent: any;
  @AuctionAction('getDetail')
  private getDetail: any;
  @AuctionAction('create')
  private create: any;

  @AuctionAction('delete')
  private delete: any;
  @AuctionAction('update')
  private update: any;

  @RecordGetter('getRecords')
  private records: RecordTableDto[];
  @RecordAction('getAuctionRecordsForAdmin')
  private loadRecords: any;
  @RecordAction('updateRecordDatesByAuctionId')
  private updateRecordDatesByAuctionId: any;

  private timeTo: string = null;
  private timeFrom: string = null;
  private editLoading: boolean = false;
  private itemLoaded: boolean = false;
  private questionDialog: boolean = false;
  private objectToDelete: AuctionTableDto = undefined;
  private state: number = 1;
  private formActive: boolean = false;
  private headers: any[] = [];
  private isActive: boolean = false;
  private isEnded: boolean = false;
  private pagination: any = {
      rowsPerPage: 10,
      totalItems: 0,
  };
  private selecteAuction: AuctionTableDto = undefined;

  private tempAuctions: AuctionTableDto[] = [];

  @Watch('isActive') private changeFilterIsActive(isActive) {
    if (isActive) {
      if (this.tempAuctions.length <= 0) {
        Object.assign(this.tempAuctions, this.auctions);
      }

      if (this.isEnded) {
        this.auctions = this.tempAuctions.filter((item) => item.isActive || item.isEnded);
      } else {
        this.auctions = this.tempAuctions.filter((item) => item.isActive);
      }
    } else {
      if (this.isEnded) {
        this.auctions = this.tempAuctions.filter((item) => item.isEnded);
      } else {
        this.auctions = this.tempAuctions;
      }
    }
  }

  @Watch('isEnded') private changeFilterIsEnded(isEnded) {
    if (isEnded) {
      if (this.tempAuctions.length <= 0) {
        Object.assign(this.tempAuctions, this.auctions);
      }

      if (this.isActive) {
        this.auctions = this.tempAuctions.filter((item) => item.isActive || item.isEnded);
      } else {
        this.auctions = this.tempAuctions.filter((item) => item.isEnded);
      }
    } else {
      if (this.isActive) {
        this.auctions = this.tempAuctions.filter((item) => item.isActive);
      } else {
        this.auctions = this.tempAuctions;
      }
    }
  }

  @Watch('auctions') private changeUsers(auctions) {
      if (auctions !== undefined && auctions.length > 0) {
          this.pagination.totalItems = auctions.length;
      }
  }

  private mounted() {
        this.headers.push({
            text: this.settings.resource.name,
            align: 'left',
            sortable: true,
            value: 'name' });
        this.headers.push({
            text: this.settings.resource.validFrom,
            align: 'right',
            sortable: true,
            value: 'validFrom' });
        this.headers.push({
            text: this.settings.resource.validTo,
            align: 'left',
            sortable: true,
            value: 'validTo' });
        this.headers.push({
            text: this.settings.resource.active,
            align: 'center',
            sortable: true,
            value: 'isActive' });
        this.headers.push({
            text: this.settings.resource.ended,
            align: 'center',
            sortable: true,
            value: 'isEnded' });
        this.headers.push({
            text: this.settings.resource.numberOfRecords,
            align: 'rigth',
            sortable: false,
            value: 'numberOfRecords' });
        this.headers.push({
            text: this.settings.resource.action,
            align: 'center',
            sortable: true,
            value: 'action' });
  }

  get labelTimeFrom(): string {
    return this.settings.resource.timeFrom;
  }

  get labelTimeTo(): string {
    return this.settings.resource.timeTo;
  }

  get questionWarning(): string {
    return this.settings.resource.warning;
  }

  get questionMessage(): string {
    return this.settings.resource.messageDeleteItem;
  }

  get labelName(): string {
    return this.settings.resource.name;
  }

  get labelValidFrom(): string {
    return this.settings.resource.validFrom;
  }

  get labelValidTo(): string {
    return this.settings.resource.validTo;
  }

  get labelIsActive(): string {
    return this.settings.resource.active;
  }

  get labelIsEnded(): string {
    return this.settings.resource.ended;
  }

  get pages() {
      if (this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null) {
            return 0;
      }
      return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage);
  }

  private notValidAndNotEnded(item: AuctionTableDto): boolean {
    const currentDate = new Date();
    const itemDate = new Date(item.validTo);
    return itemDate <= currentDate
      && (item.isActive || !item.isEnded);
  }

  private validAndActive(item: AuctionTableDto): boolean {
    const currentDate = new Date();
    const dateTo = new Date(item.validTo);
    const dateFrom = new Date(item.validFrom);

    return dateTo >= currentDate
      && dateFrom <= currentDate
      && item.isActive
      && !item.isEnded;
  }

  private validAndNotActive(item: AuctionTableDto): boolean {
    const currentDate = new Date();
    const dateTo = new Date(item.validTo);
    const dateFrom = new Date(item.validFrom);

    return dateFrom >= currentDate
      && !item.isEnded;
  }

  private backToList(): void {
    this.initCurrent().then((response) => {
      this.formActive = false;
      this.itemLoaded = false;
      this.selecteAuction = undefined;
    });
  }

  private edit(item: AuctionTableDto): void {
    if (item.id > 0) {
      this.editLoading = true;
      this.getDetail(item.id).then((response) => {
        this.formActive = response as boolean;
        const { current } = this.auction;
        const fromHours: string = current.validFrom.getHours().toString();
        const fromMinutes: string = current.validFrom.getMinutes().toString();

        const toHours: string = current.validTo.getHours().toString();
        const toMinutes: string = current.validTo.getMinutes().toString();

        const fH: string = '00'.substring(fromHours.length) + fromHours;
        const fM: string = '00'.substring(fromMinutes.length) + fromMinutes;

        const tH: string = '00'.substring(toHours.length) + toHours;
        const tM: string = '00'.substring(toMinutes.length) + toMinutes;

        this.timeFrom = `${fH}:${fM}`;
        this.timeTo = `${tH}:${tM}`;
        this.editLoading = false;
      });
    }
  }

    private items(item: AuctionTableDto): void {
      if (item.id > 0) {
        this.editLoading = true;
        this.selecteAuction = item;
        this.loadRecords(item.id).then((response) => {
          this.itemLoaded = true;
          if (response) {
            this.editLoading = false;
          }
        });
      }
    }

  private wantToDeleteRecord(item: AuctionTableDto): void {
    this.questionDialog = true;
    this.objectToDelete = item;
  }

  private deleteAuction(decision: boolean): void {
    if (this.objectToDelete.id > 0 && decision) {
      this.delete(this.objectToDelete.id).then((response) => {
        if (response) {
          this.questionDialog = false;
        }
      });
    } else {
      this.questionDialog = false;
      this.objectToDelete = undefined;
    }
  }

  private newAuction() {
    this.initCurrent().then((response) => {
      this.timeFrom = undefined;
      this.timeTo = undefined;
      this.formActive = response as boolean;
    });
  }

  private submit(): void {
    this.$validator.validateAll().then((response) => {
      if (response) {
        const fromHours: number = this.timeFrom === undefined
            ? 0
            : parseInt((this.timeFrom as string).split(':')[0], 0);
        const fromMinutes: number = this.timeFrom === undefined
            ? 0
            : parseInt((this.timeFrom as string).split(':')[1], 0);
        const toHours: number = this.timeTo === undefined
            ? 0
            : parseInt((this.timeTo as string).split(':')[0], 0);
        const toMinutes: number = this.timeTo === undefined
            ? 0
            : parseInt((this.timeTo as string).split(':')[1], 0);

        const fromDate: Date = new Date(Date.UTC(
            this.auction.current.validFrom.getFullYear(),
            this.auction.current.validFrom.getMonth(),
            this.auction.current.validFrom.getDate(),
            fromHours,
            fromMinutes,
            0,
            0,
        ));

        const toDate: Date = new Date(Date.UTC(
              this.auction.current.validTo.getFullYear(),
              this.auction.current.validTo.getMonth(),
              this.auction.current.validTo.getDate(),
              toHours,
              toMinutes,
              0,
              0,
          ));

        this.auction.current.validFrom = fromDate;
        this.auction.current.validTo = toDate;

        if (this.auction.current.id === undefined ||
            this.auction.current.id <= 0) {
            this.create(this.auction.current).then((respRecord) => {
              if (respRecord) {
                this.backToList();
              }
            });
        } else {
          const auctionId = this.auction.current.id;
          this.update(this.auction.current).then((respRecord) => {
            if (respRecord) {
              this.updateRecordDatesByAuctionId(auctionId).then((resp) => {
                this.backToList();
              });
            }
          });
        }
      }
    });
  }
}

