








































































































































import Component from 'vue-class-component';
import { State } from 'vuex-class';
import { SettingsState, AuthState } from './../store/types';

import {
  LoginFormComponent,
} from './../components';

import BaseView from './BaseView.vue';

@Component({
    components: {
        LoginFormComponent,
    },
})
export default class MainMenuDesktop extends BaseView {
    @State('settings')
    private settings: SettingsState;
    @State('auth')
    private auth: AuthState;

    get pageLogo(): string {
         return `${this.settings.apiUrl.replace('/api', '')}/img/logo-small.png`;
    }
}

