


















import Component from 'vue-class-component';
import { Action, Getter, namespace } from 'vuex-class';

import BaseView from '../BaseView.vue';
import GdprTableComponent from '@/components/User/GdprTableComponent.vue';
import { GdprRecordTableDto } from './../../poco';

const ProfileAction = namespace('profile', Action);

@Component({
  components: {
    GdprTableComponent,
  },
})
export default class GdprAdministration extends BaseView {
  @ProfileAction('getAllGdprRecordsForAdmin')
  private getRecords: any;

  private records: GdprRecordTableDto[] = [] as GdprRecordTableDto[];
  private isLoading: boolean = true;

  public mounted() {
    this.getRecords().then((response) => {
      if (response) {
        this.records = response as GdprRecordTableDto[];
        this.isLoading = false;
      }
    });
  }
}

