



















import { Component, Prop } from 'vue-property-decorator';
import { Record } from './../model';

import BaseComponent from '@/components/BaseComponent.vue';

@Component({})
export default class RecordComponent extends BaseComponent {
    @Prop({ default: null })
    public record: Record;
    @Prop({ type: Boolean, default: false })
    public isListDetail;
}

