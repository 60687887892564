













import Component from 'vue-class-component';

import BaseView from './BaseView.vue';
import { AuctionEndedComponent } from './../components';

@Component({
  components: {
    AuctionEndedComponent,
  },
})
export default class AuctionEnded extends BaseView {
}

