





































































































































































import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter, namespace } from 'vuex-class';

import FormBaseComponent from '../FormBaseComponent.vue';
import {
    GdprApplicationType,
    GdprRecord,
    Country,
} from './../../model';

const ProfileAction = namespace('profile', Action);
const SettingsGetter = namespace('settings', Getter);

@Component({})
export default class GdprFromComponent extends FormBaseComponent {
    @SettingsGetter('getCountries')
    private countries: Country[];
    @SettingsGetter('getLanguage')
    private language: string;
    @ProfileAction('createGdprRequest')
    private sendGdpr: any;

    private newGdprRecord: GdprRecord = {} as GdprRecord;
    private applicationTypes: any[] = [];
    private sent: boolean = false;

    public mounted() {
        this.applicationTypes = [{
            id: GdprApplicationType.correctionPersonalInformation,
            name: this.settings.resource.correctionPersonalInformation,
        },
        {
            id: GdprApplicationType.deletingPersonalInformation,
            name: this.settings.resource.deletingPersonalInformation,
        },
        {
            id: GdprApplicationType.reportPersonalInformation,
            name: this.settings.resource.reportPersonalInformation,
        },
        {
            id: GdprApplicationType.newObjection,
            name: this.settings.resource.newObjection,
        }];
    }

    private submit(): void {
        this.$validator.validateAll().then((response) => {
            if (response) {
                this.sendGdpr(this.newGdprRecord).then((resp1) => {
                    this.sent = resp1;
                });
            }
        });
    }

    get labelFirstName(): string {
        return this.settings.resource.firstName;
    }

    get labelLastName(): string {
        return this.settings.resource.lastName;
    }

    get labelEmail(): string {
        return this.settings.resource.email;
    }

    get labelPhoneNumber(): string {
        return this.settings.resource.phoneNumber;
    }

    get labelStreet(): string {
        return this.settings.resource.street;
    }

    get labelCity(): string {
        return this.settings.resource.city;
    }

    get labelPostCode(): string {
        return this.settings.resource.postCode;
    }

    get labelCountry(): string {
        return this.settings.resource.country;
    }

    get labelCompanyName(): string {
        return this.settings.resource.companyName;
    }

    get labelCompanyNumber(): string {
        return this.settings.resource.companyNumber;
    }

    get labelBirthNumber(): string {
        return this.settings.resource.birthNumber;
    }

    get labelDescription(): string {
        return this.settings.resource.moreDescription;
    }

    get labelApplicationType(): string {
        return this.settings.resource.applicationType;
    }
}

