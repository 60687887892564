
















































import { Component, Prop } from 'vue-property-decorator';
import { State, Action, Getter, namespace } from 'vuex-class';

import BaseComponent from './BaseComponent.vue';

import { PriceComponent, LoadingComponent } from './../components';

import { Record } from './../model';
import { RecordTableDto } from './../poco';

const RecordAction = namespace('record', Action);
const RecordGetter = namespace('record', Getter);

@Component({
    components: {
        PriceComponent,
        LoadingComponent,
    },
})
export default class AuctionEndedComponent extends BaseComponent {
  @RecordGetter('getEndedRecors') private records: RecordTableDto[];
  @RecordAction('getLatestEndedRecords') private loadRecods: any;

  private isLoading: boolean = false;

  private mounted() {
    this.isLoading = true;
    this.loadRecods(10).then(() => {
        this.isLoading = false;
    });
  }

  private firstImagePath(record: Record): string {
    const { files } = record;
    if (files.length === 0) {
      return '';
    }
    const rf = files[0];
    return `/${rf.path}/${rf.recordId}/images/${rf.name}`;
  }
}

