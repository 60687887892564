









import BaseView from './BaseView.vue';
import Component from 'vue-class-component';

@Component({})
export default class ForDealers extends BaseView {}

