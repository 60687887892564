





















import { Component, Prop, Watch } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';

import { AuthUser, RecordTableDto } from './../../poco';

import BaseComponent from '../BaseComponent.vue';

const AuthGetter = namespace('auth', Getter);

@Component({})
export default class ThumbsComponent extends BaseComponent {
    @Prop({ default: undefined })
    private record: RecordTableDto;

    @AuthGetter('getCurrentLoggedUser')
    private user: AuthUser;

    private isBidding(biddingIds: number[]): boolean {
        return biddingIds.indexOf(this.user.userId) !== -1;
    }
}
