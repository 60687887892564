



































































import { Component } from 'vue-property-decorator';
import { Getter, Action, State, namespace } from 'vuex-class';

import LoadingComponent from '@/components/helpers/LoadingComponent.vue';
import { Address } from './../model';
import { RecordTableDto } from './../poco';

import { ProfileState } from './../store/types';

import {
  UserDetailComponent,
  CustomerDetailComponent,
  AddressComponent,
  AuctionSummaryComponent,
} from './../components';

import BaseView from './BaseView.vue';

const ProfileGetter = namespace('profile', Getter);
const ProfileAction = namespace('profile', Action);

@Component({
  components: {
    UserDetailComponent,
    CustomerDetailComponent,
    AddressComponent,
    AuctionSummaryComponent,
    LoadingComponent,
  },
})
export default class Customer extends BaseView {
  @State('profile')
  public profile: ProfileState;

  @ProfileGetter('getCurrentAuctions')
  private actualRecords: RecordTableDto[];
  @ProfileGetter('getEndedAuctions')
  private endedRecords: RecordTableDto[];
  @ProfileGetter('getCustomersFullName')
  private customersFullName: string;
  @ProfileGetter('getCustomersAddress')
  private getCustomersAddress: Address;

  @ProfileAction('initialState')
  private initProfile: any;
  @ProfileAction('loadLoggedUser')
  private load: any;
  @ProfileAction('loadUsersEndedAuctions')
  private loadEnded: any;
  @ProfileAction('loadUsersCurrentAuctions')
  private loadCurrent: any;

  private isLoading: boolean = true;

  public mounted() {
    this.initProfile().then((response) => {
      this.load().then((responseUser) => {
        this.isLoading = false;
        this.loadEnded();
        this.loadCurrent();
      });
    });
  }
}

