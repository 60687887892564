











































import BaseView from './BaseView.vue';
import Component from 'vue-class-component';

import { SettingsState } from './../store/types';

@Component({})
export default class Faq extends BaseView {
}

