



















































import { State, Action, namespace } from 'vuex-class';
import Component from 'vue-class-component';

import BaseView from '../BaseView.vue';

import { ChangePasswordDto } from './../../poco';
import { SettingsState } from './../../store/types';

const AuthAction = namespace('auth', Action);

@Component({})
export default class ResetPassword extends BaseView {
  @State('settings')
  private settings: SettingsState;

  @AuthAction('resetPassword')
  private resetPwd: any;

  private visiblePwd: boolean = false;
  private visibleRepPwd: boolean = false;
  private visibleNewPwd: boolean = false;
  private loading: boolean = false;
  private disable: boolean = false;

  private change: ChangePasswordDto = {
    repeatNewPassword: '',
    newPassword: '',
    token: '',
  } as ChangePasswordDto;

  public mounted() {
    if (this.$route.name === 'passwordReset') {
      this.change.token = this.$route.query.token;
    }
  }

  get logo(): string {
    return `${this.settings.apiUrl.replace('/api', '')}/img/home_cars_jera_trading.png`;
  }
  get labelRepeatPassword(): string {
      return this.settings.resource.repeatPassword;
  }

  get labelNewPassword(): string {
      return this.settings.resource.newPassword;
  }

  get visibleRep(): boolean {
      return this.visibleRepPwd;
  }

  set visibleRep(value: boolean) {
      this.visibleRepPwd = value;
  }

  get visibleNewPwdFnc(): boolean {
    return this.visibleNewPwd;
  }

  set visibleNewPwdFnc(value: boolean) {
    this.visibleNewPwd = value;
  }

  private submit(): void {
    this.$validator.validateAll().then((response) => {
      if (response) {
        this.loading = true;
        this.disable = true;
        this.resetPwd(this.change).then((resp1) => {
          this.loading = false;
          this.disable = false;
          if (resp1) {
            this.$router.push({ name: 'passwordResetSuccessfully' });
          }
        });
      }
    });
  }

}

