























































































































import { Component, Prop } from 'vue-property-decorator';
import { State, Action, namespace } from 'vuex-class';

import BaseComponent from './BaseComponent.vue';
import { Customer } from './../model';

const ProfileAction = namespace('profile', Action);

@Component({})
export default class CustomerDetailComponent extends BaseComponent {
  @Prop({default: undefined})
  public customer: Customer;

  @ProfileAction('updateCustomer')
  private update: any;

  private isLoading: boolean = false;

  private submitCustomer(): void {
    this.$validator.validateAll().then((response) => {
      if (response) {
        this.isLoading = true;
        this.update(this.customer).then((responseCustomer) => {
          if (responseCustomer) {
            this.isLoading = false;
          }
        });
      }
    });
  }

  get labelFirstName(): string {
    return this.settings.resource.firstName;
  }

  get labelLastName(): string {
    return this.settings.resource.lastName;
  }

  get labelTitleBefore(): string {
    return this.settings.resource.titleBefore;
  }

  get labelTitleAfter(): string {
    return this.settings.resource.titleAfter;
  }

  get labelBirthNumber(): string {
    return this.settings.resource.birthNumber;
  }

  get labelEmail(): string {
    return this.settings.resource.email;
  }

  get labelPhoneNumber(): string {
    return this.settings.resource.phoneNumber;
  }

  get labelWebPageUrl(): string {
    return this.settings.resource.webPageUrl;
  }

  get labelCompanyNumber(): string {
    return this.settings.resource.companyNumber;
  }

  get labelCompanyName(): string {
    return this.settings.resource.companyName;
  }

  get labelCompanyLegalNumber(): string {
    return this.settings.resource.companyLegalNumber;
  }
}

