



































































import { Item, Lory, Next, Prev } from 'vue-lory';
import { Component } from 'vue-property-decorator';
import { Action, Getter, namespace } from 'vuex-class';
import { Record } from './../model';
import {
    CarouselDto, RecordTableDto,
} from './../poco';
import BaseComponent from './BaseComponent.vue';
import Carousel from './helpers/Carousel.vue';
import CountdownComponent from './helpers/CountdownComponent.vue';
import LoadingComponent from './helpers/LoadingComponent.vue';
import PriceComponent from './helpers/PriceComponent.vue';
import ThumbsComponent from './helpers/ThumbsComponent.vue';




const RecordAction = namespace('record', Action);
const RecordGetter = namespace('record', Getter);
const AuctionGetter = namespace('auction', Getter);
const AuctionAction = namespace('auction', Action);

@Component({
    components: {
        Carousel,
        CountdownComponent,
        PriceComponent,
        LoadingComponent,
        ThumbsComponent,
        Lory,
        Item,
        Prev,
        Next,
    },
})
export default class AuctionGridComponent extends BaseComponent {
    @RecordGetter('getRecords')
    private records: RecordTableDto[];

    @RecordAction('loadAllPublished')
    private loadRecods: any;
    @RecordAction('loadUpdateAllPublished')
    private loadRecordUpdates: any;

    @AuctionGetter('getAuctionsCarousel')
    private auctions: CarouselDto[];

    @AuctionAction('getFutureAutions')
    private featuredAcutions: any;

    private isLoading: boolean = false;
    private refreshTimer: any = null;

    private mounted() {
        this.isLoading = true;
        this.loadRecods().then((respAuction) => {
            clearInterval(this.refreshTimer);

            this.refreshTimer = setInterval(() => {
                this.loadRecordUpdates();
            }, 30000);

            this.isLoading = false;
        });
    }

    private beforeDestroy() {
        if (this.refreshTimer !== null) {
            clearInterval(this.refreshTimer);
        }
    }

    private firstImagePath(record: Record): string {
        const { files } = record;
        if (files.length === 0) {
            return '';
        }
        const rf = files[0];
        return `/${rf.path}/${rf.recordId}/images/${rf.name}`;
    }

    private recordIdToString(record: RecordTableDto): string {
        return record.id.toString();
    }

    private detail(id: number): void {
        this.$router.push({ path: `/auctionDetail?id=${id}` });
    }
}

