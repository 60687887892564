
















































import { Component, Prop, Watch } from 'vue-property-decorator';
import { State, Action, namespace } from 'vuex-class';

import { GdprRecordTableDto } from './../../poco';
import { ProfileState } from './../../store/types';
import BaseComponent from '../BaseComponent.vue';

const ProfileAction = namespace('profile', Action);

@Component({})
export default class GdprTableComponent extends BaseComponent {
    @Prop({default: []})
    private gdprRecords: GdprRecordTableDto[];
    @Prop({default: true})
    private loading: boolean;

    private actionInProgress: boolean = false;
    private dialog: boolean = false;
    private headers: any[] = [];
    private pagination: any = {
        rowsPerPage: 10,
        totalItems: 0,
    };

    @Watch('gdprRecords')
    private changeUsers(gdprRecords) {
        if (gdprRecords !== undefined && gdprRecords.length > 0) {
            this.pagination.totalItems = gdprRecords.length;
        }
    }

    private edit(item: GdprRecordTableDto): void {
        // this.setUser(item).then((response) => {
        //     this.dialog = response;
        // });
    }

    private saveEdit(): void {
        this.actionInProgress = true;
    }

    private closeDialog(): void {
        this.dialog = false;
    }

    private mounted() {
        this.headers.push({
            text: this.settings.resource.fullName,
            align: 'left',
            sortable: true,
            value: 'fullName' });
        this.headers.push({
            text: this.settings.resource.email,
            align: 'left',
            sortable: true,
            value: 'email' });
        this.headers.push({
            text: this.settings.resource.phoneNumber,
            align: 'left',
            sortable: true,
            value: 'phoneNUmber' });
        this.headers.push({
            text: this.settings.resource.city,
            align: 'left',
            sortable: true,
            value: 'city' });
        this.headers.push({
            text: this.settings.resource.applicationType,
            align: 'left',
            sortable: true,
            value: 'type' });
        this.headers.push({
            text: this.settings.resource.processed,
            align: 'center',
            sortable: true,
            value: 'isProcessed' });
        this.headers.push({
            text: this.settings.resource.created,
            align: 'right',
            sortable: true,
            value: 'created' });
        this.headers.push({
            text: this.settings.resource.action,
            align: 'center',
            sortable: true,
            value: 'action' });
    }

    get pages() {
        if (this.pagination.rowsPerPage == null ||
          this.pagination.totalItems == null) {
              return 0;
        }
        return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage);
    }
}

