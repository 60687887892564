




























import CookieConsent from 'vue-cookieconsent-component';
import { Component, Vue } from 'vue-property-decorator';
import { State, Action, Getter, namespace } from 'vuex-class';

import { MessageComponent } from './components';

import {
  AuthState,
  ProfileState,
  SettingsState,
  MessageState,
} from './store/types';

import {
  MainMenuDesktop,
  MainMenuMobile,
  FooterView,
} from './views';

import { UserShortInfo } from './poco';

const SettingsGetter = namespace('settings', Getter);
const AuthGetter = namespace('auth', Getter);
const AuthAction = namespace('auth', Action);
const ProfileAction = namespace('profile', Action);
const SettingsAction = namespace('settings', Action);
const MessageAction = namespace('message', Action);

@Component({
  components: {
    MessageComponent,
    CookieConsent,
    FooterView,
    MainMenuDesktop,
    MainMenuMobile,
  },
})
export default class App extends Vue {
  // define states in app root
  @State('message')
  public message: MessageState;
  @State('auth')
  public auth: AuthState;
  @State('profile')
  public profile: ProfileState;
  @State('settings')
  public settings: SettingsState;

  @SettingsGetter('getTranslate')
  public resx: string;

  @AuthAction('initialState')
  public initAuth: any;
  @AuthAction('startTimer')
  public startTimer: any;

  @ProfileAction('loadByToken')
  public loadByToken: any;
  @ProfileAction('initialState')
  public initProfile: any;

  @SettingsAction('initialState')
  public initSettings: any;
  @SettingsAction('changeLanguage')
  public setLang: any;

  @MessageAction('initialState')
  public initMessage: any;

  private isLoading: boolean = false;
  /**
   * When app is mounted, then initialize stores
   * and restore auth when is available
   */
  public mounted() {
    const element = this.$el.parentElement.getElementsByClassName('api')[0];
    const apiUrl = element.getAttribute('data-bind');
    this.isLoading = true;
    this.initMessage().then((response) => {
      this.initSettings(apiUrl).then((settings) => {
        const parent = element.parentNode;
        parent.removeChild(element);
        this.initProfile().then((x) => {
          if (this.auth.timer != null
            && this.auth.secondsToLogout > 0
            && this.auth.token !== undefined
            && this.auth.isAuthenticated) {
            this.startTimer();
          }

          if (this.auth.isAuthenticated) {
              this.setLang(this.profile.user.language);
          } else {
            this.initAuth();
          }
          this.isLoading = false;
        });
      });
    });
  }
}
