


















import Component from 'vue-class-component';
import { Action, Getter, namespace } from 'vuex-class';

import BaseView from '../BaseView.vue';
import AdminAuctionTableComponent from '@/components/auction/AdminAuctionTableComponent.vue';
import { AuctionTableDto } from './../../poco';

const AuctionAction = namespace('auction', Action);
const AuctionGetter = namespace('auction', Getter);

@Component({
  components: {
    AdminAuctionTableComponent,
  },
})
export default class AuctionAdministration extends BaseView {
  @AuctionGetter('getTableAuctions')
  private auctions: AuctionTableDto[];

  @AuctionAction('getAllForAdmin')
  private loadAuctions: any;

  private isLodading: boolean = true;

  public mounted() {
    this.loadAuctions().then((response) => {
      if (response) {
        this.isLodading = false;
      }
    });
  }
}

