
























import { Component, Prop, Watch } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';

import BaseComponent from '../BaseComponent.vue';
import PriceComponent from './PriceComponent.vue';

import { BidDto } from './../../poco';
import Helpers from './../../helpers';

@Component({
      components: {
        PriceComponent,
    },
})
export default class CustomerInfoComponent extends BaseComponent {
  @Prop({ default: [] })
  private bids: BidDto[];
  @Prop({ default: true })
  private anonymizeUser: boolean;
  @Prop({ default: 10 })
  private pageRows: number;
  @Prop({ default: false })

  private withHeaders: boolean;
  private headers: any[] = [];
  private pagination: any = {
      rowsPerPage: this.pageRows,
      totalItems: 0,
  };

  @Watch('bids')
  private changeUsers(bids) {
      if (bids !== undefined && bids.length > 0) {
          this.pagination.totalItems = bids.length;
      }
  }

  private mounted() {
    if (this.withHeaders) {
      this.headers.push({
          text: this.settings.resource.date,
          align: 'left',
          sortable: true,
          value: 'created' });
      this.headers.push({
          text: this.settings.resource.userName,
          align: 'left',
          sortable: true,
          value: 'userName' });
      if (this.anonymizeUser) {
        this.headers.push({
            text: '',
            align: 'right',
            sortable: true,
            value: 'bidedBy' });
        }
      this.headers.push({
          text: this.settings.resource.currentPrice,
          align: 'right',
          sortable: true,
          value: 'currentPrice' });
    }
  }

  private anonymize(str: string): string {
        return Helpers.anonymizeString(str, 1, str.length);
  }

  get pages() {
    if (this.pagination.rowsPerPage == null ||
      this.pagination.totalItems == null) {
          return 0;
    }
    return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage);
  }

}
