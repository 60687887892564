


















import Component from 'vue-class-component';
import { Getter, Action, namespace } from 'vuex-class';

import BaseView from '../BaseView.vue';
import AdminRecordTableComponent from '@/components/auction/AdminRecordTableComponent.vue';

import { Record } from './../../model';
import { RecordTableDto } from './../../poco';

const RecordAction = namespace('record', Action);
const RecordGetter = namespace('record', Getter);

@Component({
  components: {
    AdminRecordTableComponent,
  },
})
export default class RecordsAdministration extends BaseView {
    @RecordGetter('getRecords')
    private records: RecordTableDto[];

    @RecordAction('getAllForAdmin')
    private loadRecords: any;

    private isLodading: boolean = true;

    public mounted() {
      this.loadRecords().then((response) => {
        if (response) {
          this.isLodading = false;
        }
      });
  }
}

