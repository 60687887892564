
































import { Component, Vue } from 'vue-property-decorator';

import FormBaseComponent from '@/components/FormBaseComponent.vue';
import { Dictionary } from './../../store/types';

@Component({})
export default class HomeComponent extends FormBaseComponent {

    private headers: Dictionary<string> = {
        cs: 'Vítejte na stránkách společnosti JERA Trading s.r.o.',
        en: '',
        ru: '',
        de: '',
        sk: '',
    };

    private contents: Dictionary<string> = {
        cs: `Naše společnost se zabývá zajišťováním prodeje nepotřebné techniky
        pro firmy a společnosti pomocí internetových aukcí konaných na našich
        stránkách. Prodávající se díky spolupráci s námi zbavuje téměř veškeré
        administrativní zátěže související s prodejem techniky. Díky prodeji pomocí
        veřejné internetové aukci se jedná o naprosto transparentní prodej. Pro
        kupující je hlavní výhodou nákupu u naší společnosti koupě ověřené techniky,
        zpravidla po prvním majiteli, pravidelně servisované a s poctivými km. Mezi
        naše největší obchodní partnery patří Sokolovská uhelná, právní nástupce, a.s.`,
        en: ``,
        ru: ``,
        de: ``,
        sk: ``,
    };

    get header(): string {
        return this.headers[this.settings.language];
    }

    get content(): string {
        return this.contents[this.settings.language];
    }
}

