
























































import { Component, Prop } from 'vue-property-decorator';
import { Action, namespace } from 'vuex-class';

import { Record } from './../model';

import BaseComponent from './BaseComponent.vue';
import CountdownComponent from './helpers/CountdownComponent.vue';

const RecordAction = namespace('record', Action);

@Component({
    components: {
        CountdownComponent,
    },
})
export default class AuctionTableComponent extends BaseComponent {
    @Prop({default: undefined})
    public records: Record[];

    @RecordAction('getDetail')
    public loadRecord: any;

    private isLoading: boolean = false;
    private headers: any[] = [];
    private pagination: any = {
        rowsPerPage: 10,
        totalItems: 0,
    };

    public mounted() {
        this.headers.push({
            text: this.settings.resource.name,
            align: 'left',
            sortable: true,
            value: 'name' });
        this.headers.push({
            text: this.settings.resource.state,
            align: 'left',
            sortable: false,
            value: 'state' });
        this.headers.push({
            text: this.settings.resource.currentPrice,
            align: 'right',
            sortable: true,
            value: 'currentPrice' });
        this.headers.push({
            text: this.settings.resource.minimumBid,
            align: 'right',
            sortable: true,
            value: 'minimumBid' });
        this.headers.push({
            text: this.settings.resource.numberOfBids,
            align: 'right',
            sortable: true,
            value: 'numberOfBids' });
        this.headers.push({
            text: this.settings.resource.endOfAuction,
            align: 'left',
            sortable: true,
            value: 'validTo' });
        this.headers.push({
            text: this.settings.resource.action,
            align: 'center',
            sortable: true,
            value: 'action' });
    }

    private countDownId(id: number): string {
        return `count-down-${id}`;
    }

    private recordIdToString(record: Record): string {
        return record.id.toString();
    }

    private detail(record: Record): void {
        this.isLoading = true;
        this.loadRecord(record.id).then((response) => {
        const result = response as boolean;
        this.isLoading = false;
        if (result) {
            this.$router.push({ name: 'auctionDetail' });
        }
        });
    }

    get pages() {
        if (this.pagination.rowsPerPage == null ||
          this.pagination.totalItems == null) {
              return 0;
        }
        return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage);
    }
}

