


















import Component from 'vue-class-component';
import { Action, Getter, namespace } from 'vuex-class';

import BaseView from '../BaseView.vue';
import UserTableComponent from '@/components/User/UserTableComponent.vue';
import { UserSimpleDto } from './../../poco';

const ProfileAction = namespace('profile', Action);
const ProfileGetter = namespace('profile', Getter);

@Component({
  components: {
    UserTableComponent,
  },
})
export default class UserAdministration extends BaseView {
  @ProfileAction('getAllUsersForAdmin')
  private getUsers: any;

  @ProfileGetter('getList')
  private users: UserSimpleDto[];

  private isLoading: boolean = true;

  public mounted() {
    this.getUsers().then((response) => {
      if (response) {
        this.isLoading = false;
      }
    });
  }

}

